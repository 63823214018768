import { useFormik } from "formik";
import { useState } from "react";
import Button from "../components/Others/Common/Button";
import InputWithLabel from "../components/Others/Common/InputWithLabel";
import Layout from "../components/Others/Layout";
import InputErrorMessage from "../components/Others/Utils/InputErrorMessage";
import TostMessage from "../components/Others/Utils/TostMessage";
import change_password_validation from "../helper/formikValidation/change_password_validation";
import makeFetch from "../hooks/makeFetch";

export default function Security() {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    onSubmit: submit_handler,
    validate: change_password_validation,
  });

  async function submit_handler(values: any) {
    setLoading(true);
    const { result } = await makeFetch(
      "put",
      "auth/change-password",
      values,
      setLoading
    );
    if (result.error) return TostMessage(result.error, "error");

    TostMessage("Password Updated!", "success");
  }

  return (
    <Layout>
      <div className="p-6 w-full space-y-4 md:space-y-6 sm:p-8">
        <form
          onSubmit={formik.handleSubmit}
          className="space-y-4 md:space-y-6 bg-white p-5 rounded shadow border"
          action="#"
        >
          <div>
            <InputWithLabel
              label="New Password"
              placeholder="New Password"
              type="password"
              {...formik.getFieldProps("password")}
              name="password"
            />

            <InputErrorMessage
              touched={formik.touched.password}
              error={formik.errors.password}
            />
          </div>
          <div>
            <InputWithLabel
              label="Confirm New Password"
              placeholder="Confirm New Password"
              type="password"
              {...formik.getFieldProps("confirm_password")}
              name="confirm_password"
            />

            <InputErrorMessage
              touched={formik.touched.confirm_password}
              error={formik.errors.confirm_password}
            />
          </div>

          <Button type="submit" loading={loading}>
            Change Password
          </Button>
        </form>
      </div>
    </Layout>
  );
}
