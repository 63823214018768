import { useState, useEffect } from "react";
import SmallButton from "../components/Others/Common/SmallButton";
import WarningPopup from "../components/Others/Common/WarningPopup";
import Layout from "../components/Others/Layout";
import TableLayout from "../components/Others/TableLayout";
import TostMessage from "../components/Others/Utils/TostMessage";
import { rootContextType, useRootContext } from "../context/RootContext";
import makeFetch from "../hooks/makeFetch";
import { depositType } from "../types/rootStateTypes";
import { useDeposits } from "../context/DepositsContext";
import axiosInstance from "../helper/axiosInstance";

const Deposits = () => {
  const { dispatch: rootDispatch }: rootContextType = useRootContext();

  const {
    deposits,
    setDeposits,
    page,
    setPage,
    limit,
    setLimit,
    totalPages,
    setTotalPages,
    totalDeposits,
    setTotalDeposits,
  } = useDeposits();

  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<depositType>();

  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewDeposits, setPreviewDeposits] = useState<depositType[]>();
  const [previewVal, setPreviewVal] = useState("all");

  function changeSelect(e: any) {
    const value = e?.target?.value?.toLowerCase();
    setPreviewVal(value);
    if (value === "pending") {
      const filtered: any = deposits?.filter((d) => d?.status === "pending");
      setPreviewDeposits(filtered);
    } else if (value === "success") {
      const filtered: any = deposits?.filter((d) => d?.status === "success");
      setPreviewDeposits(filtered);
    } else if (value === "rejected") {
      const filtered: any = deposits?.filter((d) => d?.status === "rejected");
      setPreviewDeposits(filtered);
    } else {
      setPreviewDeposits(deposits);
    }
  }

  useEffect(() => {
    if (previewVal === "pending") {
      const filtered: any = deposits?.filter((d) => d?.status === "pending");
      setPreviewDeposits(filtered);
    } else if (previewVal === "success") {
      const filtered: any = deposits?.filter((d) => d?.status === "success");
      setPreviewDeposits(filtered);
    } else if (previewVal === "rejected") {
      const filtered: any = deposits?.filter((d) => d?.status === "rejected");
      setPreviewDeposits(filtered);
    } else {
      setPreviewDeposits(deposits);
    }
  }, [deposits, previewVal]);

  function cancelPopup() {
    setShowApprovePopup(false);
    setShowRejectPopup(false);
  }

  async function updateStatus(status: string) {
    let providedData: any = {
      deposit_id: selected?._id,
      status,
    };

    if (status === "success") {
      providedData.user_id = selected?.user?._id;
      providedData.amount = selected?.amount;
    }

    const { result } = await makeFetch(
      "put",
      "deposit/update-status",
      providedData,
      setLoading
    );

    cancelPopup();
    if (result.error) return TostMessage(result.error, "error");

    setDeposits((prev: depositType[]) => {
      return prev.map((deposit) =>
        deposit._id === selected?._id ? result.data.deposit : deposit
      );
    });
  }

  const [loadingId, setLoadingId] = useState<any>();
  async function deleteDeposit(deposit_id?: string) {
    setLoadingId(deposit_id);
    const { result } = await makeFetch(
      "delete",
      "deposit/delete",
      {
        deposit_id,
      },
      setLoading
    );
    if (result.error) return TostMessage(result.error, "error");
    rootDispatch({
      type: "remove_deleted_deposit",
      payload: result?.data?.deleted,
    });

    setDeposits((prev: depositType[]) => [
      ...prev.filter((order) => order._id !== deposit_id),
    ]);
  }

  const [paginationLoading, setPaginationLoading] = useState<any>(false);

  async function handleShowPerPage(e: React.ChangeEvent<HTMLSelectElement>) {
    setPaginationLoading(true);
    const limit_value: any = e.target.value;
    setLimit(limit_value);
    try {
      axiosInstance
        .get(
          `/get-deposits?page=${page}&limit=${
            limit_value === "all" ? totalDeposits : limit_value
          }`,
          {
            headers: {
              token: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((result) => {
          setDeposits(result?.data?.deposits);
          setTotalDeposits(result?.data?.totalDeposits);
          setTotalPages(result?.data?.totalPages);
          setPaginationLoading(false);
        });
    } catch (error) {}
  }

  async function changePagination(curr_page: any) {
    setPaginationLoading(true);
    try {
      axiosInstance
        .get(`/get-deposits?page=${curr_page}&limit=${limit}`, {
          headers: {
            token: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          setDeposits(result?.data?.deposits);
          setTotalDeposits(result?.data?.totalDeposits);
          setTotalPages(result?.data?.totalPages);
          setPaginationLoading(false);
        });
    } catch (error) {}
  }

  // fetched orders
  useEffect(() => {
    if (deposits && deposits?.length > 0) return;
    const token = localStorage.getItem("token");
    axiosInstance
      .get(`/get-deposits?page=${page}&limit=${limit}`, {
        headers: {
          token: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setDeposits(result?.data?.deposits);
        setTotalDeposits(result?.data?.totalDeposits);
        setTotalPages(result?.data?.totalPages);
      });
  }, [page, limit, deposits, setDeposits, setTotalPages, setTotalDeposits]);

  return (
    <Layout>
      <TableLayout
        paginations={{
          currentPage: page,
          totalPages: totalPages,
          onPageChange: setPage,
          changePagination: changePagination,
          showPerPageChange: handleShowPerPage,
          loading: paginationLoading,
          totalItems: totalDeposits,
        }}
        pageTitle="Deposits"
        setSeaerch={setSearch}
        selectOptions={["All", "Pending", "Success", "Rejected"]}
        allStatusCount={{
          pending: deposits?.filter((d) => d?.status === "pending").length,
          success: deposits?.filter((d) => d?.status === "success").length,
          rejected: deposits?.filter((d) => d?.status === "rejected").length,
        }}
        changeSelect={changeSelect}
        tableHeader={[
          "#",
          "Amount",
          "Pay Method",
          "Trasaction ID",
          "User Email",
          "Date",
          "Status",
          "Actions",
        ]}
      >
        {previewDeposits
          ?.filter((d) =>
            d?.transaction_id
              ?.toLocaleLowerCase()
              ?.includes(search?.toLocaleLowerCase())
          )
          .map((deposit, index) => (
            <tr key={deposit?._id}>
              <td> {index + 1} </td>
              <td> {deposit?.amount} </td>
              <td>
                <div className="flex flex-col items-center">
                  <span className="bg-gray-100 px-1 py-0.5 rounded shadow">
                    {deposit?.pay_method}
                  </span>
                  <span>{deposit?.pay_via}</span>
                </div>
              </td>

              <td>
                <span className="text-base text-green-700 tracking-wider">
                  {deposit?.transaction_id}
                </span>
              </td>
              <td>
                <small>{deposit?.user?.email}</small>
              </td>
              <td>
                <small>
                  {new Date(deposit?.createdAt).toLocaleDateString()}
                </small>
              </td>
              <td>
                {deposit?.status === "success" ? (
                  <span className="bg-green-600 text-white px-2 py-1 rounded shadow">
                    {deposit?.status}
                  </span>
                ) : (
                  <span className="bg-gray-50 text-gray-600 px-2 py-1 rounded shadow">
                    {deposit?.status}
                  </span>
                )}
              </td>
              <td>
                <div className="flex gap-x-2">
                  {deposit?.status === "pending" ? (
                    <>
                      <SmallButton
                        onClick={() => {
                          setShowApprovePopup(true);
                          setSelected(deposit);
                        }}
                        className="bg-green-600"
                      >
                        Approve
                      </SmallButton>
                      <SmallButton
                        onClick={() => {
                          setShowRejectPopup(true);
                          setSelected(deposit);
                        }}
                        className="bg-red-400"
                      >
                        Reject
                      </SmallButton>
                    </>
                  ) : (
                    <SmallButton
                      loading={loading && loadingId === deposit?._id}
                      onClick={() => deleteDeposit(deposit?._id)}
                      className="bg-red-400"
                    >
                      Delete
                    </SmallButton>
                  )}
                </div>
              </td>
            </tr>
          ))}
      </TableLayout>

      {previewDeposits?.length === 0 && (
        <div className="text-lg p-5 w-full text-center font-semibold text-yellow-700">
          {previewVal === "all" ? (
            <span> No Deposits!</span>
          ) : (
            <p>
              <span>No</span>
              <span className="capitalize mx-2">{previewVal}</span>
              <span>Deposits</span>
            </p>
          )}
        </div>
      )}

      <WarningPopup
        cancel={cancelPopup}
        confirmFunc={() => updateStatus("success")}
        loading={loading}
        confirmButtonText="Confirm"
        warningTitle="Have you check properly?"
        warningInfo={`TrxID: ${selected?.transaction_id}`}
        display={showApprovePopup}
      />

      <WarningPopup
        cancel={cancelPopup}
        confirmFunc={() => updateStatus("rejected")}
        loading={loading}
        confirmButtonText="Yes, Reject"
        warningTitle="Want to Reject this request?"
        warningInfo={`TrxID: ${selected?.transaction_id}`}
        display={showRejectPopup}
      />
    </Layout>
  );
};

export default Deposits;
