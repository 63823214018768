import { useState } from "react";
import Input from "../Others/Common/Input";
import { uid } from "uid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Button from "../Others/Common/Button";
import TostMessage from "../Others/Utils/TostMessage";
import makeFetch from "../../hooks/makeFetch";
import { useRootContext } from "../../context/RootContext";
import imageUpload from "../../helper/imageUpload";

const AddGamePopup = ({ cancelPopup }) => {
  const { dispatch: rootDispatch } = useRootContext();

  const [thumbnail, set_thumbnail] = useState("");
  const [thumbnail_uploading, set_thumbnail_uploading] = useState(false);
  const [recharge, set_recharge] = useState([]);
  const [game_title, set_game_title] = useState("");
  const [field_name, set_field_name] = useState("");
  const [specific_fields, set_specific_fields] = useState([]);
  const [loading, setLoading] = useState(false);

  function remove_field(i) {
    const filtered = specific_fields?.filter((_, ind) => ind !== i);
    set_specific_fields(filtered);
  }

  function add_field() {
    if (field_name) {
      set_specific_fields((p) => [...p, field_name]);
      set_field_name("");
    }
  }

  const [recharge_details, set_recharge_details] = useState({
    title: "",
    price: 0,
    sold_out: false,
  });

  function remove_recharge(uid) {
    set_recharge((p) => {
      return p?.filter((r) => r?.uid !== uid);
    });
  }

  function add_recharge() {
    if (recharge_details?.title && recharge_details?.price) {
      set_recharge((p) => [
        ...p,
        { ...recharge_details, sold_out: false, uid: uid() },
      ]);
      set_recharge_details({ title: "", price: 0, sold_out: false });
    }
  }

  async function upload_thumbnail(e) {
    const data = await imageUpload(
      e?.target?.files[0],
      set_thumbnail_uploading
    );

    set_thumbnail(data?.secure_url);
  }

  async function add_game_handler(e) {
    e?.preventDefault();
    if (recharge?.length < 1) {
      TostMessage('Please provide min 1 "Recharge Package"! ', "warning");
      return;
    }
    if (specific_fields.length < 1) {
      TostMessage('Please provide min 1 "Specific Field"! ', "warning");
      return;
    }
    const game = {
      recharge,
      specific_fields,
      thumbnail,
      title: game_title,
      specific_rules: [],
    };

    console.log("game");
    console.log(game);
    const { result } = await makeFetch("post", "game/create", game, setLoading);
    cancelPopup();
    if (result?.error) return TostMessage(result.error, "error");
    rootDispatch({ type: "put_new_game", payload: result?.data?.game });
    TostMessage("Added New Game!");
  }

  return (
    <div className="overflow-hidden fixed inset-0 m-auto flex justify-center items-center h-modal z-[111]">
      <div
        onClick={cancelPopup}
        className="w-full h-screen bg-black bg-opacity-50 flex items-center justify-center z-40 fixed inset-0 m-auto"
      />

      <div className="relative z-50 px-4 w-full max-w-2xl">
        <div
          style={{ maxHeight: "80vh", overflowY: "auto" }}
          className="relative bg-white rounded-lg shadow-xl shadow-gray-700"
        >
          <div className="flex justify-between items-start p-5 rounded-t border-b">
            <h3 className="text-xl font-semibold">Add Item</h3>
            <button
              onClick={cancelPopup}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-toggle="product-modal"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <div className="p-6 space-y-6">
            <form onSubmit={add_game_handler}>
              <div className="w-full">
                <label
                  htmlFor="game_title"
                  className="block mb-2 text-base font-medium text-gray-900"
                >
                  Game Title
                </label>
                <Input
                  placeholder="Example: Free Fire"
                  id="game_title"
                  onChange={(e) => set_game_title(e.target.value)}
                  required
                />
              </div>

              {thumbnail ? (
                <div className="py-6 flex flex-col">
                  <p className="block mb-2 text-base font-medium text-gray-900">
                    Thumbnail
                  </p>
                  <img
                    src={thumbnail}
                    className="w-40 mx-auto lg:mx-0 rounded ring-2"
                    alt="game thumbnail"
                  />
                </div>
              ) : (
                <div className="flex justify-center items-center w-full py-6">
                  {thumbnail_uploading ? (
                    <div className="w-full h-40 flex items-center justify-center relative">
                      <div className="w-full h-40 bg-black animate-pulse rounded"></div>
                      <p className="absolute inset-0 m-auto text-base font-medium w-fit h-fit text-white">
                        Uploading...
                      </p>
                    </div>
                  ) : (
                    <label
                      htmlFor="thumbnail"
                      className="flex flex-col w-full h-32 rounded border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-50"
                    >
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <svg
                          className="w-10 h-10 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                          ></path>
                        </svg>
                        <p className="py-1 text-sm text-gray-600">
                          Upload a thumbnail or drag and drop
                        </p>
                        <p className="text-xs text-gray-500">
                          PNG, JPG, GIF up to 10MB
                        </p>
                      </div>
                      <input
                        required
                        type="file"
                        className=""
                        id="thumbnail"
                        onChange={upload_thumbnail}
                      />
                    </label>
                  )}
                </div>
              )}

              <div>
                <p className="block mb-2 text-base font-medium text-gray-900">
                  All Recharge
                </p>
                <div className="flex flex-wrap gap-2">
                  {recharge?.map((r, i) => (
                    <div
                      key={r?.uid}
                      className="bg-gray-200 text-dark-primary p-4 rounded shadow text-xs font-medium space-x-4 relative"
                    >
                      <span>{r?.title}</span>
                      <b>৳ {r?.price}</b>
                      <span
                        onClick={() => remove_recharge(r?.uid)}
                        className="absolute top-1 right-1 text-red-500 text-xs cursor-pointer block"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </span>
                    </div>
                  ))}
                </div>

                <div className="flex flex-items items-end justify-between gap-3 flex-col sm:flex-row py-6">
                  <div className="w-full">
                    <label
                      htmlFor="pac_title"
                      className="text-sm font-medium block mb-1"
                    >
                      Package Title
                    </label>
                    <Input
                      required={false}
                      id="pac_title"
                      value={recharge_details?.title}
                      placeholder="Example:  500 DIAMOND"
                      onChange={(e) =>
                        set_recharge_details((p) => ({
                          ...p,
                          title: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="pac_price"
                      className="text-sm font-medium block mb-1"
                    >
                      Package Price
                    </label>
                    <Input
                      required={false}
                      value={
                        recharge_details?.price > 0 && recharge_details?.price
                      }
                      id="pac_price"
                      type="number"
                      placeholder="Example:  200"
                      onChange={(e) =>
                        set_recharge_details((p) => ({
                          ...p,
                          price: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <button
                    onClick={add_recharge}
                    type="button"
                    className="h-11 px-4 rounded focus:ring-2 shadow bg-cyan-900 text-white"
                  >
                    Add
                  </button>
                </div>
              </div>

              <div className="py-6">
                <p className="mb-1">
                  Add Specific Field <small>(optional)</small>
                </p>
                <div className="flex flex-wrap gap-2 py-3">
                  {specific_fields?.map((f, i) => (
                    <div
                      key={f + i}
                      className="bg-gray-200 border rounded px-5 py-3 relative"
                    >
                      <span> {f} </span>
                      <span
                        onClick={() => remove_field(i)}
                        className="cursor-pointer text-xs absolute top-1 right-1"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex items-center justify-between gap-x-2">
                  <Input
                    required={false}
                    value={field_name || ""}
                    onChange={(e) => set_field_name(e?.target?.value)}
                    placeholder="Field name, Exmaple: Player Id"
                  />
                  <button
                    type="button"
                    onClick={add_field}
                    className="px-3 gap-x-1 h-11 flex items-center justify-center bg-cyan-800 text-white rounded"
                  >
                    <span>Add </span>
                    <span>Field</span>
                  </button>
                </div>
              </div>

              <div className="py-6 rounded-b border-t border-gray-200">
                <Button type="submit" loading={loading}>
                  Add Game
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddGamePopup;
