import { createContext, useState, useContext } from "react";
import { depositType } from "../types/rootStateTypes";

interface contextType {
  deposits?: depositType[];
  setDeposits?: any;
  page?: any;
  setPage?: any;
  limit?: any;
  setLimit?: any;
  totalPages?: any;
  setTotalPages?: any;
  totalDeposits?: any;
  setTotalDeposits?: any;
}

export const DepositsContext = createContext<contextType>({});

export const DepositsProvider = ({ children }: any) => {
  const [deposits, setDeposits] = useState([]);
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [totalPages, setTotalPages] = useState<any>(1);
  const [totalDeposits, setTotalDeposits] = useState<any>(0);

  return (
    <DepositsContext.Provider
      value={{
        deposits,
        setDeposits,
        page,
        setPage,
        limit,
        setLimit,
        totalPages,
        setTotalPages,
        totalDeposits,
        setTotalDeposits,
      }}
    >
      {children}
    </DepositsContext.Provider>
  );
};

export const useDeposits = () => {
  return useContext(DepositsContext);
};
