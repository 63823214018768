import { useState, useEffect } from "react";
import WarningPopup from "../components/Others/Common/WarningPopup";
import Layout from "../components/Others/Layout";
import TableLayout from "../components/Others/TableLayout";
import SmallButton from "../components/Others/Common/SmallButton";
import { useAuthContext, authContextType } from "../context/AuthContext";
import { userType } from "../types/authStateTypes";
import makeFetch from "../hooks/makeFetch";
import TostMessage from "../components/Others/Utils/TostMessage";
import axiosInstance from "../helper/axiosInstance";
import { useUsers } from "../context/UsersContext";

const Users = () => {
  const { state: authState }: authContextType = useAuthContext();

  const {
    users,
    setUsers,
    page,
    setPage,
    limit,
    setLimit,
    total_pages,
    setTotalPages,
    total_users,
    setTotalUsers,
  } = useUsers();

  const [selected, setSelected] = useState<userType>();

  const [search, setSearch] = useState("");

  const [showDisabledPopup, setShowDisabledPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [disabledId, setDisabledId] = useState<any>("");

  const [paginationLoading, setPaginationLoading] = useState(false);

  function cancelPopup() {
    setShowDisabledPopup(false);
    setShowDeletePopup(false);
  }

  async function makeDelete() {
    const { result } = await makeFetch(
      "delete",
      "auth/delete",
      { user_id: selected?._id },
      setLoading
    );
    cancelPopup();
    if (result?.error) TostMessage(result.error, "error");
    setUsers((prev: userType[]) => [
      ...prev.filter((user) => user._id !== selected?._id),
    ]);
  }

  async function toggleDisabled(disabled: boolean, id?: string) {
    setDisabledId(id);
    const { result } = await makeFetch(
      "put",
      "auth/toggle-disabled",
      { user_id: id || selected?._id, disabled },
      setLoading
    );
    cancelPopup();
    if (result?.error) TostMessage(result.error, "error");

    setUsers((prev: userType[]) => [
      ...prev.map((user) => (user?._id === id ? { ...user, disabled } : user)),
    ]);
  }

  async function handleShowPerPage(e: React.ChangeEvent<HTMLSelectElement>) {
    setPaginationLoading(true);
    const limit_value: any = e.target.value;
    setLimit(limit_value);
    try {
      axiosInstance
        .get(
          `/get-users?page=${page}&limit=${
            limit_value === "all" ? total_users : limit_value
          }`,
          {
            headers: {
              token: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((result) => {
          setUsers(result?.data?.users);
          setTotalUsers(result?.data?.totalUsers);
          setTotalPages(result?.data?.totalPages);
          setPaginationLoading(false);
        });
    } catch (error) {}
  }

  async function changePagination(curr_page: any) {
    setPaginationLoading(true);
    try {
      axiosInstance
        .get(`/get-users?page=${curr_page}&limit=${limit}`, {
          headers: {
            token: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          setUsers(result?.data?.users);
          setTotalUsers(result?.data?.totalUsers);
          setTotalPages(result?.data?.totalPages);
          setPaginationLoading(false);
        });
    } catch (error) {}
  }

  // fetched users
  useEffect(() => {
    if (users && users?.length > 0) return;
    const token = localStorage.getItem("token");
    axiosInstance
      .get(`/get-users?page=${page}&limit=${limit}`, {
        headers: {
          token: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setUsers(result?.data?.users);
        setTotalUsers(result?.data?.totalUsers);
        setTotalPages(result?.data?.totalPages);
      });
  }, [page, limit, users, setUsers, setTotalPages, setTotalUsers]);

  return (
    <Layout>
      <TableLayout
        paginations={{
          currentPage: page,
          totalPages: total_pages,
          onPageChange: setPage,
          changePagination: changePagination,
          showPerPageChange: handleShowPerPage,
          loading: paginationLoading,
          totalItems: total_users,
        }}
        tableHeader={[
          "#",
          "Email",
          "Balance",
          "Orders",
          "Deposited",
          "Created",
          "Actions",
        ]}
        pageTitle="Users"
        setSeaerch={setSearch}
      >
        {users
          ?.filter((user) => user?._id !== authState?.user?._id)
          ?.filter((user) =>
            user?.email
              ?.toLocaleLowerCase()
              ?.includes(search?.toLocaleLowerCase())
          )
          ?.map((user, index) => (
            <tr key={user?._id}>
              <td>{index + 1}</td>
              <td>
                <small>{user?.email}</small>
              </td>
              <td>৳ {user?.balance}</td>
              <td>{0}</td>
              <td>{0}</td>
              <td>
                <small>{new Date(user?.createdAt).toLocaleDateString()}</small>
              </td>
              <td>
                <div className="flex gap-x-2">
                  <SmallButton
                    onClick={() => {
                      setSelected(user);
                      setShowDeletePopup(true);
                    }}
                    className="bg-red-500"
                  >
                    Delete
                  </SmallButton>
                  {user?.disabled ? (
                    <SmallButton
                      loading={loading && user?._id === disabledId}
                      onClick={() => toggleDisabled(false, user?._id)}
                      className="bg-pink-500"
                    >
                      Remove Disable
                    </SmallButton>
                  ) : (
                    <SmallButton
                      onClick={() => {
                        setSelected(user);
                        setShowDisabledPopup(true);
                      }}
                      className="bg-pink-500"
                    >
                      Disable
                    </SmallButton>
                  )}
                </div>
              </td>
            </tr>
          ))}
      </TableLayout>

      <WarningPopup
        display={showDisabledPopup}
        cancel={cancelPopup}
        confirmFunc={() => toggleDisabled(true, selected?._id)}
        loading={loading}
        warningTitle="Want to disabled this user?"
        warningInfo={selected?.email}
      />

      <WarningPopup
        display={showDeletePopup}
        cancel={cancelPopup}
        confirmFunc={makeDelete}
        loading={loading}
        warningTitle="Want to Delete this user?"
        warningInfo={selected?.email}
      />
    </Layout>
  );
};

export default Users;
