import { useEffect, useState } from "react";
import AddGamePopup from "../components/Games/AddGamePopup";
import EditGamePopup from "../components/Games/EditGamePopup";
import SmallButton from "../components/Others/Common/SmallButton";
import WarningPopup from "../components/Others/Common/WarningPopup";
import Layout from "../components/Others/Layout";
import TableLayout from "../components/Others/TableLayout";
import TostMessage from "../components/Others/Utils/TostMessage";
import { rootContextType, useRootContext } from "../context/RootContext";
import makeFetch from "../hooks/makeFetch";
import { gameType } from "../types/rootStateTypes";

const Games = () => {
  const { state: rootState, dispatch: rootDispatch }: rootContextType =
    useRootContext();
  const [search, setSearch] = useState("");
  const [games, setGames] = useState<[gameType?]>();
  useEffect(() => {
    setGames(rootState?.games);
  }, [rootState]);

  const [loading, setLoading] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showAddNewPopup, setShowAddNewPopup] = useState(false);
  const [selected, setSelected] = useState<gameType>();

  function cancelPopup() {
    setShowDeletePopup(false);
    setShowAddNewPopup(false);
    setShowEditPopup(false);
  }

  async function gameDelete() {
    const { result } = await makeFetch(
      "delete",
      "game/delete",
      {
        game_id: selected?._id,
      },
      setLoading
    );
    cancelPopup();
    if (result.error) TostMessage(result.error, "error");
    rootDispatch({
      type: "remove_deleted_game",
      payload: result?.data?.deleted,
    });
  }

  return (
    <Layout>
      <TableLayout
        pageTitle="Games"
        setSeaerch={setSearch}
        headerButton={{
          text: "Add New Game",
          func: () => setShowAddNewPopup(true),
        }}
        tableHeader={[
          "#",
          "title",
          "Thumbnail",
          "Available Recharge",
          "Actions",
        ]}
      >
        {games
          ?.filter((g) =>
            g?.title?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())
          )
          ?.map((game, index: number) => (
            <tr key={game?._id}>
              <td>{index + 1}</td>
              <td>
                <div className="flex flex-col">
                  {game?.sold_out && (
                    <p className="bg-yellow-200 px-1 w-fit">sold out</p>
                  )}
                  {game?.title}
                </div>
              </td>
              <td>
                <img className="w-24 rounded" src={game?.thumbnail} alt="" />
              </td>
              <td>
                <div className="flex flex-wrap gap-3">
                  {game?.recharge?.map((recharge) => (
                    <div
                      key={recharge?.uid}
                      className="bg-gray-200 px-2 py-1 rounded w-fit flex space-x-3 gax-3 font-medium"
                    >
                      <p>
                        <small> {recharge?.title} </small> :
                        <small>৳{recharge?.price}</small>
                      </p>
                      {recharge?.sold_out && (
                        <small className="bg-yellow-200 px-1">sold out</small>
                      )}
                    </div>
                  ))}
                </div>
              </td>
              <td>
                <div className="flex items-center gap-x-2">
                  <SmallButton
                    onClick={() => {
                      setSelected(game);
                      setShowEditPopup(true);
                    }}
                    className="bg-green-600"
                  >
                    Edit
                  </SmallButton>
                  <SmallButton
                    onClick={() => {
                      setSelected(game);
                      setShowDeletePopup(true);
                    }}
                    className="bg-red-400"
                  >
                    Delete
                  </SmallButton>
                </div>
              </td>
            </tr>
          ))}
      </TableLayout>

      {showAddNewPopup && <AddGamePopup cancelPopup={cancelPopup} />}

      {showEditPopup && (
        <EditGamePopup cancelPopup={cancelPopup} selected={selected} />
      )}

      <WarningPopup
        display={showDeletePopup}
        cancel={cancelPopup}
        confirmFunc={gameDelete}
        loading={loading}
        confirmButtonText="Confirm"
        warningTitle="Want to delete this game?"
        warningInfo={selected?.title}
      />
    </Layout>
  );
};

export default Games;
