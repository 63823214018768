import { createContext, useReducer, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../helper/axiosInstance";
import authStateType from "../types/authStateTypes";

const AuthState: authStateType = {
  user: {},
};

export interface authActionsType {
  type: "set_user";
  payload?: any;
}

const reducer = (state: authStateType, action: authActionsType) => {
  const { type, payload } = action;
  switch (type) {
    case "set_user":
      return {
        ...state,
        user: payload,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<any>(AuthState);

const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, AuthState);

  // Authorize User
  useEffect(() => {
    const token = localStorage.getItem("token");
    function makeLogout() {
      localStorage.removeItem("token");
      dispatch({ type: "set_user", payload: {} });
      navigate("/", { replace: true });
    }
    if (token && !state.user?.email) {
      axiosInstance
        .get(`/auth/admin-authorize`, {
          headers: {
            email: "sagar@gmail.com",
            token: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch({ type: "set_user", payload: res?.data?.user });
        })
        .catch((error) => {
          if (error?.message) {
            makeLogout();
          }
        });
    }
  }, [navigate, state]);

  function logout() {
    localStorage.removeItem("token");
    dispatch({ type: "set_user", payload: {} });
    navigate("/", { replace: true });
  }

  return (
    <AuthContext.Provider value={{ state, dispatch, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

export interface authContextType {
  state: authStateType;
  dispatch: ({ type, payload }: authActionsType) => void;
  logout: () => void;
}

export { AuthProvider, useAuthContext };
