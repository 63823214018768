import { Navigate } from "react-router-dom";
import { useAuthContext, authContextType } from "../context/AuthContext";

export const RequireAuthorize = ({ children }: any) => {
  const token = localStorage.getItem("token");
  if (!token) {
    return <Navigate to={"/login"} replace={true}></Navigate>;
  }
  return children;
};

export const PublicRoute = ({ children }: any) => {
  const { state }: authContextType = useAuthContext();
  const token = localStorage.getItem("token");

  if (state.user?.email || token) {
    return <Navigate to={"/"} replace={true}></Navigate>;
  }
  return children;
};
