import { createContext, useState, useContext } from "react";
import { orderType } from "../types/rootStateTypes";

interface contextType {
  orders?: orderType[];
  setOrders?: any;
  page?: any;
  setPage?: any;
  limit?: any;
  setLimit?: any;
  total_pages?: any;
  setTotalPages?: any;
  total_orders?: any;
  setTotalOrders?: any;
}

export const OrdersContext = createContext<contextType>({});

export const OrdersProvider = ({ children }: any) => {
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [total_pages, setTotalPages] = useState<any>(1);
  const [total_orders, setTotalOrders] = useState<any>(0);

  return (
    <OrdersContext.Provider
      value={{
        orders,
        setOrders,
        page,
        setPage,
        limit,
        setLimit,
        total_pages,
        setTotalPages,
        total_orders,
        setTotalOrders,
      }}
    >
      {children}
    </OrdersContext.Provider>
  );
};

export const useOrders = () => {
  return useContext(OrdersContext);
};
