import {
  faMoneyBillTrendUp,
  faShoppingCart,
  faUser,
  faUsers,
  faInfoCircle,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Layout from "../components/Others/Layout";
import { rootContextType, useRootContext } from "../context/RootContext";
import { useState, useEffect } from "react";
import axiosInstance from "../helper/axiosInstance";

function Item({ Icon, text, total, link, className }: any) {
  return (
    <div className="rounded overflow-hidden">
      <div
        className={`${className} group p-5 flex items-center justify-between`}
      >
        <div className="flex flex-col gap-4">
          <h2 className="text-3xl font-semibold">{total}</h2>
          <h2 className="text-base">{text}</h2>
        </div>
        <h1 className="text-4xl opacity-30 group-hover:scale-110 transform transition-all">
          <FontAwesomeIcon icon={Icon} />
        </h1>
      </div>
      <Link to={link}>
        <div
          className={`${className} bg-opacity-70 p-1 flex text-sm items-center justify-center gap-x-2`}
        >
          <span>More Info</span>
          <span>
            <FontAwesomeIcon icon={faArrowAltCircleRight} />
          </span>
        </div>
      </Link>
    </div>
  );
}

const Home = () => {
  const { state: rootState, dispatch: rootDispatch }: rootContextType =
    useRootContext();
  const {
    available_balance,
    today_deposited,
    today_sell,
    total_users,
    today_registered_users,
    today_orders,
    today_canceled_orders,
    today_completed_orders,
    pending_orders,
    total_deposits,
  } = rootState;

  const [count, setCount] = useState(0);
  // fetched games
  useEffect(() => {
    if (rootState?.total_users > 1) return;
    rootDispatch({ type: "set_fetching", payload: true });
    setCount(1);
    if (count === 1) return;
    const token = localStorage.getItem("token");
    axiosInstance
      .get("/admin-data", {
        headers: {
          token: `Bearer ${token}`,
        },
      })
      .then((result) => {
        rootDispatch({
          type: "set_data",
          payload: {
            available_balance: result?.data?.available_balance,
            today_deposited: result?.data?.today_deposited,
            today_sell: result?.data?.today_sell,
            today_orders: result?.data?.today_orders,
            today_completed_orders: result?.data?.today_completed_orders,
            today_canceled_orders: result?.data?.today_canceled_orders,
            pending_orders: result?.data?.pending_orders,
            total_users: result?.data?.total_users,
            today_registered_users: result?.data?.today_registered_users,
            total_deposits: result?.data?.total_deposits,
            games: result?.data?.games,
            ...result.data?.admin_details,
          },
        });
      })
      .finally(() => {
        rootDispatch({ type: "set_fetching", payload: false });
      });
  }, [count, rootDispatch, rootState?.total_users]);

  return (
    <Layout>
      {rootState?.fetching ? (
        <div className="bg-black fixed inset-0 w-full h-screen z-[200] bg-opacity-60 flex items-center justify-center">
          <img
            className="w-12"
            src="https://icaengineeringacademy.com/wp-content/uploads/2019/01/ajax-loading-gif-transparent-background-2.gif"
            alt=""
          />
        </div>
      ) : null}

      <div className="grid grid-cols-1 gap-4 py-6 mb-6 w-full sm:grid-cols-2 2xl:grid-cols-3">
        <div className="col-span-full">
          <h3 className="text-base bg-gray-500 text-gray-50 tracking-wider font-semibold w-fit px-4 py-2 rounded shadow space-x-3">
            <span>Deposit Details</span>
            <span>
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
          </h3>
        </div>
        <Item
          Icon={faMoneyBillTrendUp}
          text="Available Balance"
          total={`৳` + available_balance?.toLocaleString("en")}
          className="bg-orange-500 text-white"
          link="/deposit-success"
        />
        <Item
          Icon={faMoneyBillTrendUp}
          text="Today Deposited"
          total={`৳` + today_deposited?.toLocaleString("en")}
          className="bg-pink-500 text-white"
          link="/deposit-success"
        />
        <Item
          Icon={faMoneyBillTrendUp}
          text="Today Sell"
          total={`৳` + today_sell?.toLocaleString("en")}
          className="bg-yellow-500 text-white"
          link="/orders-completed"
        />
        <Item
          Icon={faMoneyBillTrendUp}
          text="Total Deposited"
          total={`৳` + total_deposits?.toLocaleString("en")}
          className="bg-blue-500 text-white"
          link="/orders-completed"
        />

        <div className="col-span-full mt-10">
          <h3 className="text-base bg-gray-500 text-gray-50 tracking-wider font-semibold w-fit px-4 py-2 rounded shadow space-x-3">
            <span> Orders Details</span>
            <span>
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
          </h3>
        </div>

        <Item
          Icon={faShoppingCart}
          text="Today's Orders!"
          total={today_orders?.toLocaleString()}
          className="bg-cyan-600 text-white"
          link="/orders"
        />
        <Item
          Icon={faShoppingCart}
          text="Today's Completed Orders!"
          total={today_completed_orders?.toLocaleString()}
          className="bg-green-600 text-white"
          link="/orders-completed"
        />
        <Item
          Icon={faShoppingCart}
          text="Today's Canceled Orders!"
          total={today_canceled_orders?.toLocaleString()}
          className="bg-rose-600 text-white"
          link="/orders-canceled"
        />
        <Item
          Icon={faShoppingCart}
          text="Total Pending Orders!"
          total={pending_orders?.toLocaleString()}
          className="bg-emerald-600 text-white"
          link="/orders"
        />
        <div className="col-span-full mt-10">
          <h3 className="text-base bg-gray-500 text-gray-50 tracking-wider font-semibold w-fit px-4 py-2 rounded shadow space-x-3">
            <span> Users Details</span>
            <span>
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
          </h3>
        </div>
        <Item
          Icon={faUser}
          text="Today's registered users!"
          total={today_registered_users?.toLocaleString()}
          className="bg-yellow-600 text-white"
          link="/users"
        />
        <Item
          Icon={faUsers}
          text="Total users"
          total={total_users?.toLocaleString()}
          className="bg-green-600 text-white"
          link="/users"
        />
      </div>
    </Layout>
  );
};

export default Home;
