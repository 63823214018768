type values_type = {
  phone?: string;
  whatsapp?: string;
  email?: string;
  twitter?: string;
  facebook?: string;
  instagram?: string;
  youtube?: string;
};

export default function contact_info_validation(values: values_type) {
  const errors: values_type = {};

  if (!values.phone) {
    errors.phone = "Please provide phone number!";
  }

  if (!values.whatsapp) {
    errors.whatsapp = "Please provide whatsapp number!";
  }

  if (!values.email) {
    errors.email = "Please provide contact email for user support!";
  }

  return errors;
}
