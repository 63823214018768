import { AuthProvider } from "./AuthContext";
import { UsersProvider } from "./UsersContext";
import { RootProvider } from "./RootContext";
import { OrdersProvider } from "./OrdersContext";
import { DepositsProvider } from "./DepositsContext";
const ContextProvider = ({ children }) => {
  return (
    <RootProvider>
      <UsersProvider>
        <DepositsProvider>
          <OrdersProvider>
            <AuthProvider>{children}</AuthProvider>
          </OrdersProvider>
        </DepositsProvider>
      </UsersProvider>
    </RootProvider>
  );
};

export default ContextProvider;
