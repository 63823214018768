import axios from "axios";

// export const api = `https://topup-backend.xdomainhost.com/api`;
// export const api = `https://node.srbdtopup.com/api`;
export const api = `https://server.rgtopup.com/api`;
// export const api = `http://localhost:8000/api`;

const axiosInstance = axios.create({
  baseURL: api,

  headers: {
    "Content-Type": "application/json;charset=utf8",
    token: `Bearer ${localStorage.getItem("token")}`,
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

export default axiosInstance;
