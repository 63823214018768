import { faTrashCan, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import Button from "../components/Others/Common/Button";
import Input from "../components/Others/Common/Input";
import Layout from "../components/Others/Layout";
import TostMessage from "../components/Others/Utils/TostMessage";
import { rootContextType, useRootContext } from "../context/RootContext";
import convertToBase64 from "../helper/convertToBase64";
import imageUpload from "../helper/imageUpload";
import makeFetch from "../hooks/makeFetch";

export default function BannersSetting() {
  const { state: rootState, dispatch: rootDispatch }: rootContextType =
    useRootContext();

  const [banners, setBanners] = useState<any>([
    {
      img: "",
      link: "",
    },
  ]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setBanners(rootState?.banners);
  }, [rootState]);

  function change_handler(e: any, i2: any) {
    setBanners((p: any) => {
      return p.map((b: any, i: any) =>
        i === i2 ? { ...b, link: e?.target?.value } : b
      );
    });
  }

  function add_new_banner() {
    setBanners((p: any) => [...p, { img: "", link: "" }]);
  }

  function remove_handler(i2: any) {
    setBanners((p: any) => {
      return p.filter((b: any, i: any) => i !== i2);
    });
  }

  const [b_uploading, set_b_uploading] = useState(false);
  async function banner_upload(e: any, i2: any) {
    const data = await imageUpload(e?.target?.files[0], set_b_uploading);

    setBanners((p: any) => {
      return p.map((b: any, i: any) =>
        i === i2 ? { ...b, img: data?.secure_url } : b
      );
    });
  }

  async function update_banners() {
    const updatedBanners = banners?.filter((b: any) => b?.img);
    if (updatedBanners?.length === 0)
      return TostMessage("Minimum 1 banner is required!", "warning");
    const { result } = await makeFetch(
      "put",
      "/admin-details",
      { banners },
      setLoading
    );
    if (result.error) return TostMessage(result.error, "error");
    rootDispatch({
      type: "set_data",
      payload: { ...result.data?.admin_details },
    });
    TostMessage("Successfully Updated!", "success");
  }

  return (
    <Layout>
      <div className="space-y-9">
        {banners?.map((b: any, i: any) => (
          <div
            key={i}
            className="w-full space-y-2 p-6 bg-white rounded shadow relative"
          >
            <button
              title="remove this banner"
              onClick={() => remove_handler(i)}
              className="text-red-500 text-xl absolute top-1.5 right-1.5"
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
            {b?.img ? (
              <img src={b?.img} alt="" className="w-full lg:w-96 rounded" />
            ) : (
              <div className="p-5 flex flex-col text-dark-primary items-center justify-center gap-y-3 border border-dashed border-dark-primary relative">
                {b_uploading ? (
                  <h2>Uploading</h2>
                ) : (
                  <>
                    <h2>Upload Banner</h2>
                    <h2>
                      <FontAwesomeIcon icon={faUpload} />
                    </h2>
                  </>
                )}
                <input
                  title="choose banner image"
                  onChange={(e) => banner_upload(e, i)}
                  type="file"
                  className="absolute opacity-0 inset-0 block m-auto z-50 w-full h-full"
                />
              </div>
            )}

            <Input
              placeholder="Banner link"
              value={banners[i]?.link || ""}
              onChange={(e) => change_handler(e, i)}
            />
          </div>
        ))}
        <div className="w-fit">
          <Button onClick={add_new_banner}> Add New Banner </Button>
        </div>
        <Button loading={loading} onClick={update_banners}>
          Update Banners
        </Button>
      </div>
    </Layout>
  );
}
