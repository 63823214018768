import { Link } from "react-router-dom";
import Button from "../Common/Button";

const TableLayoutHeader = ({
  setSearch,
  pageTitle,
  headerButton,
}: {
  setSearch?: any;
  pageTitle?: string;
  headerButton?: {
    text: string;
    func: any;
  };
}) => {
  return (
    <div className="block justify-between items-center p-4 mx-4 mb-6 bg-white rounded-lg shadow-xl shadow-gray-200 lg:p-4 sm:flex">
      <nav
        className="w-full flex items-center gap-x-10"
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-2">
          <li className="inline-flex items-center">
            <Link
              to="/"
              className="inline-flex items-center text-gray-700 hover:text-gray-900"
            >
              <svg
                className="w-5 h-5 mr-2.5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
              </svg>
              Home
            </Link>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="w-6 h-6 text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span
                className="ml-1 text-base font-medium text-gray-500 md:ml-2"
                aria-current="page"
              >
                {pageTitle}
              </span>
            </div>
          </li>
        </ol>

        <form className="sm:pr-3 sm:mb-0" action="#" method="GET">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative sm:w-64 xl:w-96">
            <input
              type="text"
              name="email"
              id="search"
              onChange={(e) => setSearch(e.target.value)}
              className="border border-gray-300 text-gray-900 rounded focus:ring-2 focus:ring-fuchsia-50 focus:border-fuchsia-300 block w-full px-3 py-2 text-sm"
              placeholder="Search here..."
            />
          </div>
        </form>

        {headerButton?.text && (
          <div className="w-fit ml-auto">
            <Button onClick={headerButton.func}>{headerButton.text}</Button>
          </div>
        )}
      </nav>
    </div>
  );
};

export default TableLayoutHeader;
