import { useFormik } from "formik";
import { useState, useEffect } from "react";
import Button from "../components/Others/Common/Button";
import InputWithLabel from "../components/Others/Common/InputWithLabel";
import Layout from "../components/Others/Layout";
import InputErrorMessage from "../components/Others/Utils/InputErrorMessage";
import TostMessage from "../components/Others/Utils/TostMessage";
import { authContextType, useAuthContext } from "../context/AuthContext";
import edit_profile_validation from "../helper/formikValidation/edit_profile_validation";
import makeFetch from "../hooks/makeFetch";
import authStateType from "../types/authStateTypes";

function EditForm({ state }: { state: authStateType }) {
  const { dispatch }: authContextType = useAuthContext();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      email: state.user?.email,
      name: state.user?.name,
    },
    onSubmit: submit_handler,
    validate: edit_profile_validation,
  });

  async function submit_handler(values: any) {
    setLoading(true);
    const { result } = await makeFetch(
      "put",
      "auth/edit-profile",
      values,
      setLoading
    );

    if (result.error) return TostMessage(result.error, "error");

    dispatch({ type: "set_user", payload: result.data?.user });
    TostMessage("Profile Info Updated!", "success");
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="space-y-4 md:space-y-6 bg-white p-5 rounded shadow border"
      action="#"
    >
      <div>
        <img
          className="w-12 h-12 rounded-full mb-2"
          src={
            state?.user?.image ||
            "https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
          }
          alt=""
        />
      </div>

      <div>
        <InputWithLabel
          label="Your Name"
          placeholder="Your Name"
          type="name"
          {...formik.getFieldProps("name")}
          name="name"
        />

        <InputErrorMessage
          touched={formik.touched.name}
          error={formik.errors.name}
        />
      </div>
      <div>
        <InputWithLabel
          label="Your Email"
          placeholder="Your Email"
          type="email"
          {...formik.getFieldProps("email")}
          name="email"
        />

        <InputErrorMessage
          touched={formik.touched.email}
          error={formik.errors.email}
        />
      </div>

      <Button type="submit" loading={loading}>
        Update
      </Button>
    </form>
  );
}

export default function Profile() {
  const { state }: authContextType = useAuthContext();

  const [should_show_form, set_ssf] = useState<boolean>(false);

  useEffect(() => {
    if (state?.user?.email) {
      set_ssf(true);
    }
  }, [state]);

  return <Layout>{should_show_form && <EditForm state={state} />}</Layout>;
}
