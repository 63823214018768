type values_type = {
  bkash?: string;
  rocket?: string;
  nagat?: string;
  deposit_type?: string;
};

export default function payment_method_validation(values: values_type) {
  const errors: values_type = {};

  if (!values.deposit_type) {
    errors.deposit_type = "Required!";
  }

  if (!values.bkash) {
    errors.bkash = "Password is Required!";
  }

  if (!values.rocket) {
    errors.rocket = "Password is Required!";
  }

  if (!values.nagat) {
    errors.nagat = "Password is Required!";
  }

  return errors;
}
