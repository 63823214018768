import axiosInstance from "../helper/axiosInstance";

export default async function makeFetch(
  method: "put" | "post" | "get" | "delete",
  url: string,
  data?: object,
  setLoading?: any
) {
  if (setLoading) setLoading(true);
  let info: { data: any; error: any; status: number } = {
    data: null,
    error: null,
    status: 0,
  };

  try {
    const { data: resData, status } = await axiosInstance({
      method,
      url,
      data,
    });
    info.data = resData;
    info.status = status;
  } catch (error: any) {
    info.status = error?.response?.status;
    info.error = error?.response?.data?.message || error?.message;
  }

  if (setLoading) setLoading(false);
  return { result: info };
}
