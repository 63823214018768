import { useFormik } from "formik";
import { useState, useEffect } from "react";
import Button from "../components/Others/Common/Button";
import InputWithLabel from "../components/Others/Common/InputWithLabel";
import Layout from "../components/Others/Layout";
import InputErrorMessage from "../components/Others/Utils/InputErrorMessage";
import TostMessage from "../components/Others/Utils/TostMessage";
import { rootContextType, useRootContext } from "../context/RootContext";
import contact_info_validation from "../helper/formikValidation/contact_info_validation";
import makeFetch from "../hooks/makeFetch";
import { contact_infoType } from "../types/rootStateTypes";

function Form({ contact_info }: { contact_info?: contact_infoType }) {
  const { dispatch: rootDispatch }: rootContextType = useRootContext();

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      phone: contact_info?.phone || "",
      whatsapp: contact_info?.whatsapp || "",
      email: contact_info?.email || "",
      twitter: contact_info?.twitter || "",
      facebook: contact_info?.facebook || "",
      instagram: contact_info?.instagram || "",
      youtube: contact_info?.youtube || "",
    },
    onSubmit: submit_handler,
    validate: contact_info_validation,
  });

  async function submit_handler(values: any) {
    const { result } = await makeFetch(
      "put",
      "/admin-details",
      { contact_info: values },
      setLoading
    );
    if (result.error) return TostMessage(result.error, "error");
    rootDispatch({
      type: "set_data",
      payload: { ...result.data?.admin_details },
    });
    TostMessage("Successfully Updated!", "success");
  }

  return (
    <div className="p-6 w-full space-y-4 md:space-y-6 sm:p-8">
      <form
        onSubmit={formik.handleSubmit}
        className="space-y-4 md:space-y-6 bg-white p-5 rounded shadow border"
        action="#"
      >
        <div>
          <InputWithLabel
            label="Contact Number"
            placeholder="Contact Number"
            {...formik.getFieldProps("phone")}
            name="phone"
          />

          <InputErrorMessage
            touched={formik.touched.phone}
            error={formik.errors.phone}
          />
        </div>

        <div>
          <InputWithLabel
            label="WhatsApp Number"
            placeholder="WhatsApp Number"
            {...formik.getFieldProps("whatsapp")}
            name="whatsapp"
          />

          <InputErrorMessage
            touched={formik.touched.whatsapp}
            error={formik.errors.whatsapp}
          />
        </div>

        <div>
          <InputWithLabel
            label="Email Address"
            placeholder="Email Address"
            {...formik.getFieldProps("email")}
            name="email"
          />

          <InputErrorMessage
            touched={formik.touched.email}
            error={formik.errors.email}
          />
        </div>

        <InputWithLabel
          required={false}
          label="Twitter "
          placeholder="Twitter Link"
          {...formik.getFieldProps("twitter")}
          name="twitter"
        />
        <InputWithLabel
          required={false}
          label="Facebook "
          placeholder="Facebook Link"
          {...formik.getFieldProps("facebook")}
          name="facebook"
        />
        <InputWithLabel
          required={false}
          label="Instagram "
          placeholder="Instagram Link"
          {...formik.getFieldProps("instagram")}
          name="instagram"
        />
        <InputWithLabel
          required={false}
          label="Youtube Channel "
          placeholder="Youtube Link"
          {...formik.getFieldProps("youtube")}
          name="youtube"
        />

        <Button type="submit" loading={loading}>
          Update Info
        </Button>
      </form>
    </div>
  );
}

export default function ContactInfoSettings() {
  const { state: rootState }: rootContextType = useRootContext();

  const [should_show_form, set_ssf] = useState<boolean>(false);

  useEffect(() => {
    if (rootState?.contact_info) {
      set_ssf(true);
    }
  }, [rootState]);

  return (
    <Layout>
      {should_show_form ? (
        <Form contact_info={rootState?.contact_info} />
      ) : (
        <h1 className="p-12 text-lg text-pink-600">Loading...</h1>
      )}
    </Layout>
  );
}
