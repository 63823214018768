import { ButtonProps } from "../../../types/HTMLPropsTypes";

const Button = ({ loading, children, ...rest }: ButtonProps) => {
  return (
    <button
      {...rest}
      disabled={loading}
      className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center flex items-center justify-between transition-transform disabled:bg-primary-400 hover:scale-[1.02]"
    >
      <span></span>
      <span>{children}</span>

      {loading ? (
        <span className="block ml-4 w-6 h-6 rounded-full animate-spin border-4 border-r-transparent"></span>
      ) : (
        <span></span>
      )}
    </button>
  );
};

export default Button;
