import { createContext, useState, useContext } from "react";
import { userType } from "../types/authStateTypes";

interface contextType {
  users?: userType[];
  setUsers?: any;
  page?: any;
  setPage?: any;
  limit?: any;
  setLimit?: any;
  total_pages?: any;
  setTotalPages?: any;
  total_users?: any;
  setTotalUsers?: any;
}

export const UsersContext = createContext<contextType>({});

export const UsersProvider = ({ children }: any) => {
  const [users, setUsers] = useState<any>([]);
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [total_pages, setTotalPages] = useState<any>(1);
  const [total_users, setTotalUsers] = useState<any>(0);

  return (
    <UsersContext.Provider
      value={{
        users,
        setUsers,
        page,
        setPage,
        limit,
        setLimit,
        total_pages,
        setTotalPages,
        total_users,
        setTotalUsers,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => {
  return useContext(UsersContext);
};
