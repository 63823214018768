import Sidebar from "./Sidebar";
import Header from "./Header";

const Layout = ({ children }: any) => {
  return (
    <main className="flex p-0 m-0 justify-between w-full h-screen overflow-hidden">
      <Sidebar />
      {/* <!-- Content */}

      <div className="w-full h-screen overflow-auto">
        <Header />
        <div className="x_container space-y-10 py-3">{children}</div>
      </div>
    </main>
  );
};

export default Layout;
