export default async function imageUpload(file, setLoading) {
  if (!file) return;
  if (setLoading) {
    setLoading(true);
  }
  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", "docs_upload_example_us_preset");
  const url = "https://api.cloudinary.com/v1_1/demo/image/upload";

  const resp = await fetch(url, {
    method: "POST",
    body: formData,
  });
  const data = resp.json();

  if (setLoading) {
    setLoading(false);
  }

  return data;
}
