import { InputProps } from "../../../types/HTMLPropsTypes";

const Input = ({ ...rest }: InputProps) => {
  return (
    <div>
      <input
        type={rest?.type || "text"}
        required={rest?.required || true}
        {...rest}
        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm focus:ring-1 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
      />
    </div>
  );
};

export default Input;
