import {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState,
} from "react";
import axiosInstance from "../helper/axiosInstance";
import rootStateTypes from "../types/rootStateTypes";

const rootState: rootStateTypes = {
  available_balance: 0,
  today_deposited: 0,
  today_sell: 0,
  today_orders: 0,
  today_completed_orders: 0,
  today_canceled_orders: 0,
  pending_orders: 0,
  total_users: 0,
  today_registered_users: 0,
  total_deposits: 0,
  fetching: false,
};

export interface rootActionsType {
  type:
    | "set_fetching"
    | "set_data"
    | "toggle_sidebar"
    | "put_updated_user"
    | "put_updated_game"
    | "put_new_game"
    | "remove_deleted_user"
    | "remove_deleted_game"
    | "remove_deleted_order"
    | "remove_deleted_deposit";
  payload?: any;
}

const reducer = (state: rootStateTypes, action: rootActionsType) => {
  const { type, payload } = action;
  switch (type) {
    case "set_data":
      return { ...state, ...payload };

    case "toggle_sidebar":
      return {
        ...state,
        sidebar_visible: payload ? payload : !state.sidebar_visible,
      };

    case "put_updated_user":
      return {
        ...state,
        users: state?.users?.map((user) =>
          user?._id === payload?._id ? payload : user
        ),
      };

    case "remove_deleted_user":
      return {
        ...state,
        users: state?.users?.filter((user) => user?._id !== payload?._id),
      };

    case "put_new_game":
      return {
        ...state,
        games: state?.games?.concat(payload),
      };

    case "put_updated_game":
      return {
        ...state,
        games: state?.games?.map((game) =>
          game?._id === payload?._id ? payload : game
        ),
      };

    case "remove_deleted_game":
      return {
        ...state,
        games: state?.games?.filter((game) => game?._id !== payload?._id),
      };

    case "remove_deleted_order":
      return {
        ...state,
        orders: state?.orders?.filter((order) => order?._id !== payload?._id),
      };

    case "remove_deleted_deposit":
      return {
        ...state,
        deposits: state?.deposits?.filter(
          (deposit) => deposit?._id !== payload?._id
        ),
      };

    case "set_fetching":
      return {
        ...state,
        fetching: payload,
      };

    default:
      return state;
  }
};

const RootContext = createContext<any>(rootState);

const RootProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, rootState);

  const [count, setCount] = useState(0);
  // fetched games
  useEffect(() => {
    dispatch({ type: "set_fetching", payload: true });
    setCount(1);
    if (count === 1) return;
    const token = localStorage.getItem("token");
    axiosInstance
      .get("/admin-data", {
        headers: {
          token: `Bearer ${token}`,
        },
      })

      .then((result) => {
        dispatch({
          type: "set_data",
          payload: {
            available_balance: result?.data?.available_balance,
            today_deposited: result?.data?.today_deposited,
            today_sell: result?.data?.today_sell,
            today_orders: result?.data?.today_orders,
            today_completed_orders: result?.data?.today_completed_orders,
            today_canceled_orders: result?.data?.today_canceled_orders,
            pending_orders: result?.data?.pending_orders,
            total_users: result?.data?.total_users,
            today_registered_users: result?.data?.today_registered_users,
            total_deposits: result?.data?.total_deposits,

            games: result?.data?.games,
            ...result.data?.admin_details,
          },
        });
      })
      .finally(() => {
        dispatch({ type: "set_fetching", payload: false });
      });

    console.log("working");
  }, [count]);

  return (
    <RootContext.Provider value={{ state, dispatch }}>
      {children}
    </RootContext.Provider>
  );
};

const useRootContext = () => useContext(RootContext);

export interface rootContextType {
  state: rootStateTypes;
  dispatch: ({ type, payload }: rootActionsType) => void;
}

export { RootProvider, useRootContext };
