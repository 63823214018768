import {
  faCrown,
  faXmark,
  faUser,
  faLock,
  faSignOut,
  faGauge,
  faGamepad,
  faUsers,
  faMoneyBillTransfer,
  faShoppingCart,
  faCircleInfo,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";

function SidebarItemLink({ to = "/", title = "", Icon, nolink, onClick }) {
  function click_handle() {
    if (onClick) {
      onClick();
    }
  }
  if (nolink) {
    return (
      <li onClick={click_handle}>
        <a href="##" className="hover:bg-[#2a3744] hover:text-white">
          <h4>
            <FontAwesomeIcon icon={Icon} />
          </h4>
          <span>{title}</span>
        </a>
      </li>
    );
  }

  return (
    <li onClick={click_handle}>
      <NavLink
        to={to}
        className={({ isActive }) =>
          `${isActive ? "bg-blue-600" : "hover:bg-[#2a3744] hover:text-white"}`
        }
      >
        <h4>
          <FontAwesomeIcon icon={Icon} />{" "}
        </h4>
        <span>{title}</span>
      </NavLink>
    </li>
  );
}

function SidebarItem({ title, children }) {
  return (
    <div className="sidebar_item overflow-hidden">
      <button className="ds_title" data-ref={title}>
        <span className="tracking-wider block">{title}</span>
        <span className="text-gray-200">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 15.75l7.5-7.5 7.5 7.5"
            />
          </svg>
        </span>
      </button>

      <ul className="ds_ul transition-all px-2" data-ref={title}>
        {children}
      </ul>
    </div>
  );
}

const Sidebar = () => {
  const { logout } = useAuthContext();
  const [w_zero, set_w_zero] = useState(false);
  const sidebar_ref = useRef();

  function scroll_sidebar(e) {
    setTimeout(() => {
      const sidebar = document.querySelector(".sidebar");
      sidebar.scrollTop = 1000;
    }, 100);
  }

  useEffect(() => {
    set_w_zero(true);

    // Sidebar Toggler
    const sidebar_toggler = document.getElementById("sidebar_toggler");
    const sidebar = document.getElementById("sidebar");
    const hide_sidebar = document.getElementById("hide_sidebar");
    if (sidebar_toggler && sidebar && hide_sidebar) {
      let open = true;
      function toggle() {
        open = !open;
        if (open) {
          sidebar.style.width = "250px";
          sidebar.style.minWidth = "250px";
        } else {
          sidebar.style.minWidth = "0px";
          sidebar.style.maxWidth = "0px";
          sidebar.style.width = "0px";
        }
      }
      sidebar_toggler.addEventListener("click", toggle);
      hide_sidebar.addEventListener("click", toggle);

      if (document.documentElement.clientWidth < 768) {
        open = !open;
        console.log("load");
      }
      window.addEventListener("resize", () => {
        if (document.documentElement.clientWidth < 1024) {
          open = !open;
        }
      });
    }

    // Sidebar Item Toggler
    const all_ds_title = document.querySelectorAll(".ds_title");
    const all_ds_ul = document.querySelectorAll(".ds_ul");
    if (all_ds_title && all_ds_ul) {
      const heights = [];
      for (let i = 0; i < all_ds_ul.length; i++) {
        const ele = all_ds_ul[i];
        heights.push(ele.clientHeight);
        ele.style.height = ele.clientHeight + "px";
      }

      all_ds_title.forEach((title) => {
        let open = true;
        title.addEventListener("click", function () {
          const title_ref = this.dataset?.ref;

          const icon = this.children[1];
          if (open) {
            icon.style.transform = "rotate(90deg)";
          } else {
            icon.style.transform = "rotate(0deg)";
          }

          all_ds_ul.forEach((item, i) => {
            const item_ref = item.dataset?.ref;
            if (title_ref === item_ref) {
              if (open) {
                item.style.height = "0px";
              } else {
                item.style.height = heights[i] + "px";
              }
              open = !open;
            }
          });
        });
      });
    }
  }, []);

  return (
    <div
      ref={sidebar_ref}
      id="sidebar"
      style={{ zIndex: "100" }}
      className={`sidebar transition-all ${
        w_zero && "w-[0px] min-w-[0px]"
      } lg:min-w-[255px] lg:w-[255px] h-screen overflow-auto bg-gray-800 border-r shadow sidebar fixed lg:static inset-y-0 left-0 z-50`}
    >
      <div className="flex items-center justify-between px-4 gap-x-2 h-16 border-b-[0.5px] border-gray-500 shadow-sm sticky top-0 left-0 bg-gray-800 z-50">
        <button id="hide_sidebar" className="lg:hidden text-white">
          <FontAwesomeIcon icon={faXmark} />
        </button>

        <NavLink to="/" className="flex items-center justify-start gap-x-2">
          <p className="text-blue-600">
            <FontAwesomeIcon icon={faCrown} />
          </p>
          <p className="flex items-center justify-center">
            <span className="font-semibold text-base text-blue-500">ADMIN</span>
            <span className="font-bold text-base text-purple-300">PANEL</span>
          </p>
        </NavLink>
        <button></button>
      </div>

      <SidebarItem title="MANAGE">
        <SidebarItemLink title="Dashboard" to="/" Icon={faGauge} />
        <SidebarItemLink title="Games" to="/games" Icon={faGamepad} />
        <SidebarItemLink title="Users" to="/users" Icon={faUsers} />
        <SidebarItemLink title="Orders" to="/orders" Icon={faShoppingCart} />
        <SidebarItemLink
          to="/deposits"
          title="Deposits"
          Icon={faMoneyBillTransfer}
        />
      </SidebarItem>
      <div className="my-2 border-b-[0.5px] border-gray-500" />

      <SidebarItem title="Settings">
        <SidebarItemLink
          onClick={scroll_sidebar}
          to="/profile"
          title="Profile"
          Icon={faUser}
        />
        <SidebarItemLink
          onClick={scroll_sidebar}
          to="/security"
          title="Security"
          Icon={faLock}
        />

        <SidebarItemLink
          onClick={scroll_sidebar}
          to="/contact-settings"
          title="Contact Settings"
          Icon={faCircleInfo}
        />
        <SidebarItemLink
          onClick={scroll_sidebar}
          to="/website-settings"
          title="Website Settings"
          Icon={faCircleInfo}
        />

        <SidebarItemLink
          onClick={scroll_sidebar}
          to="/banners"
          title="Banners Setting"
          Icon={faImage}
        />

        <SidebarItemLink
          onClick={scroll_sidebar}
          to="/payment-methods"
          title="Payment Metods"
          Icon={faCircleInfo}
        />

        <SidebarItemLink
          onClick={logout}
          nolink={true}
          title="Logout"
          Icon={faSignOut}
        />
      </SidebarItem>
    </div>
  );
};

export default Sidebar;
