import { useFormik } from "formik";
import { useState, useEffect } from "react";
import Button from "../components/Others/Common/Button";
import InputWithLabel from "../components/Others/Common/InputWithLabel";
import Layout from "../components/Others/Layout";
import InputErrorMessage from "../components/Others/Utils/InputErrorMessage";
import TostMessage from "../components/Others/Utils/TostMessage";
import { rootContextType, useRootContext } from "../context/RootContext";
import payment_method_validation from "../helper/formikValidation/payment_method_validation";
import makeFetch from "../hooks/makeFetch";
import { payment_methodType } from "../types/rootStateTypes";

function Form({ payment_method }: { payment_method?: payment_methodType }) {
  const { dispatch: rootDispatch }: rootContextType = useRootContext();

  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      bkash: payment_method?.bkash || "",
      nagat: payment_method?.nagat || "",
      rocket: payment_method?.rocket || "",
      deposit_type: payment_method?.deposit_type,
    },
    onSubmit: submit_handler,
    validate: payment_method_validation,
  });

  
  async function submit_handler(values: any) {
    const { result } = await makeFetch(
      "put",
      "/admin-details",
      { payment_method: values },
      setLoading
    );
    if (result.error) return TostMessage(result.error, "error");
    rootDispatch({
      type: "set_data",
      payload: { ...result.data?.admin_details },
    });
    TostMessage("Successfully Updated!", "success");
  }


  return (
    <div className="p-6 w-full space-y-4 md:space-y-6 sm:p-8">
      <form
        onSubmit={formik.handleSubmit}
        className="space-y-4 md:space-y-6 bg-white p-5 rounded shadow border"
        action="#"
      >
        <div>
          <label
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            htmlFor="deposit_type"
          >
            Deposit Type
          </label>
          <select
            className="px-4 py-3 rounded border focus:ring bg-gray-50 w-full"
            title="User Deposit Type"
            id="deposit_type"
            {...formik.getFieldProps("deposit_type")}
            name="deposit_type"
          >
            <option value="only manual">Only Manual</option>
            <option value="only auto">Only Auto</option>
            <option value="all">All Deposit Type</option>
          </select>

          <InputErrorMessage
            touched={formik.touched.deposit_type}
            error={formik.errors.deposit_type}
          />
        </div>

        <div>
          <InputWithLabel
            label="Bkash Account Number"
            placeholder="Bkash Account Number"
            {...formik.getFieldProps("bkash")}
            name="bkash"
          />

          <InputErrorMessage
            touched={formik.touched.bkash}
            error={formik.errors.bkash}
          />
        </div>

        <div>
          <InputWithLabel
            label="Nagat Account Number"
            placeholder="Nagat Account Number"
            {...formik.getFieldProps("nagat")}
            name="nagat"
          />

          <InputErrorMessage
            touched={formik.touched.nagat}
            error={formik.errors.nagat}
          />
        </div>

        <div>
          <InputWithLabel
            label="Rocket Account Number"
            placeholder="Rocket Account Number"
            {...formik.getFieldProps("rocket")}
            name="rocket"
          />

          <InputErrorMessage
            touched={formik.touched.rocket}
            error={formik.errors.rocket}
          />
        </div>
        <Button type="submit" loading={loading}>
          Update Payment Methods
        </Button>
      </form>
    </div>
  );
}

export default function ContactInfoSettings() {
  const { state: rootState }: rootContextType = useRootContext();

  const [should_show_form, set_ssf] = useState<boolean>(false);

  useEffect(() => {
    if (rootState?.payment_method) {
      set_ssf(true);
    }
  }, [rootState]);

  return (
    <Layout>
      {should_show_form ? (
        <Form payment_method={rootState?.payment_method} />
      ) : (
        <h1 className="p-12 text-lg text-pink-600">Loading...</h1>
      )}
    </Layout>
  );
}
