import React from "react";
import classNames from "classnames";

const Btn = ({ children, page, currentPage, ...props }: any) => (
  <button
    {...props}
    className={classNames(
      "w-8 h-8 sm:w-11 sm:h-11 flex items-center justify-center text-xs sm:text-sm cursor-pointer border border-gray-300 rounded hover:bg-orange-500 transform transition-all hover:text-white disabled:opacity-50 disabled:cursor-not-allowed",
      {
        "bg-orange-600 text-white": currentPage === page,
        "scale-120 sm:text-lg": currentPage === page,
      }
    )}
  >
    {children}
  </button>
);

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  changePagination,
}: {
  currentPage: any;
  totalPages: any;
  onPageChange: any;
  changePagination: any;
}) => {
  const isFirstPage = currentPage === 1 || 0;
  const isLastPage = currentPage === totalPages || currentPage > totalPages;

  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  const handlePageChange = (page: any) => {
    if (page < 1 || page > totalPages) {
      return;
    }
    onPageChange(page);
    changePagination(page);
  };

  return (
    <div className="flex flex-wrap justify-center sm:justify-start items-center gap-1">
      <Btn
        onClick={() => handlePageChange(currentPage - 2)}
        disabled={isFirstPage || currentPage < 3}
        page={null}
        currentPage={currentPage}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
          />
        </svg>
      </Btn>

      <Btn
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={isFirstPage}
        page={null}
        currentPage={currentPage}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
      </Btn>

      {currentPage > 20 ? (
        <Btn
          onClick={() => handlePageChange(1)}
          page={1}
          currentPage={currentPage}
        >
          1
        </Btn>
      ) : null}

      {pages
        .filter((_, i) => i < currentPage + 2)
        .filter((_, i) => i > currentPage - 2)
        .map((page) =>
          page === totalPages ? null : (
            <Btn
              key={page}
              onClick={() => handlePageChange(page)}
              page={page}
              currentPage={currentPage}
            >
              {page}
            </Btn>
          )
        )}

      <Btn
        onClick={() => handlePageChange(totalPages)}
        page={totalPages}
        currentPage={currentPage}
      >
        {totalPages}
      </Btn>

      {/* next 1 */}
      <Btn
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={isLastPage}
        page={null}
        currentPage={currentPage}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </Btn>

      {/* next 2 */}
      <Btn
        onClick={() => handlePageChange(currentPage + 2)}
        disabled={isLastPage || currentPage === totalPages - 1}
        page={null}
        currentPage={currentPage}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
          />
        </svg>
      </Btn>
    </div>
  );
};

export default Pagination;
