import { ButtonProps } from "../../../types/HTMLPropsTypes";

const SmallButton = ({ loading, children, ...rest }: ButtonProps) => {
  return (
    <button
      {...rest}
      disabled={loading}
      className={`${rest.className} inline-flex items-center py-2 px-3 text-xs font-medium text-center text-white rounded-lg shadow-md shadow-gray-300 hover:scale-[1.05] transition-transform`}
    >
      <span></span>
      <span>{children}</span>

      {loading ? (
        <span className="block ml-2 w-4 h-4 rounded-full animate-spin border-2 border-r-transparent"></span>
      ) : (
        <span></span>
      )}
    </button>
  );
};

export default SmallButton;
