type values_type = {
  copyright?: string;
  logo?: string;
  location?: string;
  popup_image?: string;
  popup_text?: string;
  admin_url?: string;
  client_url?: string;
  add_money_tutorial_video_id?: string;
};

export default function web_info_validation(values: values_type) {
  const errors: values_type = {};

  if (!values.copyright) {
    errors.copyright = "Please provide phone number!";
  }

  return errors;
}
