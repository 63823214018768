import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignOut,
  faChevronDown,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { useAuthContext, authContextType } from "../../context/AuthContext";
import { useRootContext, rootContextType } from "../../context/RootContext";
import { faInternetExplorer } from "@fortawesome/free-brands-svg-icons";

const Header = () => {
  const location = useLocation();

  const { state: rootState, dispatch: rootDispatch }: rootContextType =
    useRootContext();

  const { logout, state }: authContextType = useAuthContext();

  const [open_profile_opt, set_opo] = useState(false);

  function logout_handler() {
    logout();
  }

  useEffect(() => {
    window.addEventListener("resize", function () {
      rootDispatch({
        type: "toggle_sidebar",
        payload: this.document.documentElement.clientWidth > 1024,
      });
    });
  }, [rootDispatch]);

  return (
    <header className="h-16 bg-white shadow z-50">
      <div className="h-full flex items-center relative justify-between px-5 xl:px-10">
        <div className="text-xl font-semibold tracking-wide capitalize space-x-3">
          <button id="sidebar_toggler">
            <FontAwesomeIcon icon={faBars} />{" "}
          </button>
          <span className="hidden sm:inline-block text-gray-800">
            {location.pathname.slice(1).split("-").join(" ") || "Dashboard"}
          </span>
        </div>

        <div className="flex items-center justify-center gap-x-3 sm:gap-x-4">
          {state?.user?.email ? (
            <>
              <a
                href={rootState?.web_info?.client_url || "#"}
                target="__blank"
                className="flex items-center gap-x-1 text-gray-500 hover:text-blue-500"
              >
                <FontAwesomeIcon
                  className="text-base"
                  icon={faInternetExplorer}
                />
                <span className="text-sm font-medium">website</span>
              </a>
              <div className="relative w-fit z-50">
                <button
                  onClick={() => set_opo((p) => !p)}
                  onBlur={() =>
                    setTimeout(() => {
                      set_opo(false);
                    }, 500)
                  }
                  type="button"
                  className={`w-full px-6 py-2 rounded bg-gray-100 shadow border flex items-center justify-center gap-x-1`}
                >
                  <span> {state?.user?.name?.split(" ")[0]} </span>

                  <img
                    className="h-7 w-7 rounded-full"
                    src={
                      state?.user?.image ||
                      "https://t.pimg.jp/022/903/336/1/22903336.jpg"
                    }
                    alt=""
                  />
                  <FontAwesomeIcon
                    className={`text-sm text-gray-500 transition-all transform ${
                      open_profile_opt ? "-rotate-180" : "rotate-0"
                    }`}
                    icon={faChevronDown}
                  />
                </button>

                <div
                  className={`absolute w-full bg-white rounded shadow top-full right-0 transition-all origin-top transform ${
                    open_profile_opt ? "scale-y-100" : "scale-y-0"
                  }`}
                >
                  <button
                    onClick={logout_handler}
                    className="flex items-center gap-x-2 w-full  hover:bg-gray-100 p-3"
                  >
                    <FontAwesomeIcon
                      className="text-base text-gray-500"
                      icon={faSignOut}
                    />
                    <span className="text-sm font-medium">Logout</span>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <Link
                to="/login"
                className="items-center flex text-xs sm:text-sm transition-all font-medium bg-blue-500 border border-green-600  hover:border-transparent hover:bg-blue-600 text-white px-4 sm:px-5 py-2 sm:py-2.5 rounded focus:ring-2 focus:border-transparent"
              >
                Login
              </Link>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
