interface propsType {
  cancel: any;
  confirmFunc: () => void;
  loading: boolean;
  confirmButtonText?: string;
  warningTitle: string;
  warningInfo?: string;
  display: boolean;
}

const WarningPopup = ({
  cancel,
  confirmFunc,
  loading,
  confirmButtonText,
  warningTitle,
  warningInfo,
  display,
}: propsType) => {
  if (!display) return <></>;

  // return <div className="fixed ">lorem</div>;

  return (
    <div className="overflow-hidden fixed inset-0 m-auto justify-center items-center h-modal sm:h-full flex z-[150]">
      <div
        onClick={cancel}
        className="w-full h-screen bg-black bg-opacity-50 z-40 fixed inset-0 m-auto"
      />

      <div className="relative px-4 w-full max-w-md h-full md:h-auto z-50">
        <div className="relative bg-white rounded-lg shadow-lg">
          <div className="flex justify-end p-2">
            <button
              title="cancel"
              onClick={cancel}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-toggle="delete-product-modal"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <div className="p-6 pt-0 text-center">
            <svg
              className="mx-auto w-20 h-20 text-red-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <div className="mt-5 mb-6 text-xl font-normal text-gray-500">
              <p>{warningTitle}</p>
              <p className="block px-2 py-1 bg-yellow-100 font-medium tracking-wide text-sm w-fit mx-auto">
                {warningInfo}
              </p>
            </div>
            <div className="w-full flex justify-between items-center gap-x-2">
              <a
                onClick={cancel}
                href="##"
                className="text-white bg-red-400 border border-gray-200 font-medium inline-flex items-center rounded-lg text-sm px-3 py-2.5 text-center hover:scale-[1.02] transition-transform"
                data-modal-toggle="delete-product-modal"
              >
                Cancel
              </a>

              <button
                onClick={confirmFunc}
                disabled={loading}
                className="disabled:opacity-70 disabled:cursor-not-allowed text-white bg-gradient-to-br from-green-600 to-green-600 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2.5 text-center mr-2 shadow-md shadow-gray-300 hover:scale-[1.02] transition-transform"
              >
                <span>{confirmButtonText || "Confirm"}</span>
                {loading && (
                  <span className="block w-4 h-4 border-2 ml-2 border-t-transparent rounded-full shadow animate-spin"></span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningPopup;
