import { useFormik } from "formik";
import { useState, useEffect } from "react";
import Button from "../components/Others/Common/Button";
import InputWithLabel from "../components/Others/Common/InputWithLabel";
import Layout from "../components/Others/Layout";
import InputErrorMessage from "../components/Others/Utils/InputErrorMessage";
import TostMessage from "../components/Others/Utils/TostMessage";
import { rootContextType, useRootContext } from "../context/RootContext";
import web_info_validation from "../helper/formikValidation/web_info_validation";
import imageUpload from "../helper/imageUpload";
import makeFetch from "../hooks/makeFetch";
import { web_infoType } from "../types/rootStateTypes";

function Form({ web_info }: { web_info?: web_infoType }) {
  const { dispatch: rootDispatch }: rootContextType = useRootContext();

  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      copyright: web_info?.copyright || "",
      logo: web_info?.logo || "",
      location: web_info?.location || "",
      popup_image: web_info?.popup_image || "",
      popup_text: web_info?.popup_text || "",
      admin_url: web_info?.admin_url || "",
      client_url: web_info?.client_url || "",
      add_money_tutorial_video_id: web_info?.add_money_tutorial_video_id || "",
    },
    onSubmit: submit_handler,
    validate: web_info_validation,
  });

  async function submit_handler(values: any) {
    const { result } = await makeFetch(
      "put",
      "/admin-details",
      { web_info: values },
      setLoading
    );
    if (result.error) return TostMessage(result.error, "error");
    rootDispatch({
      type: "set_data",
      payload: { ...result.data?.admin_details },
    });
    TostMessage("Successfully Updated!", "success");
  }

  const [imageUploading, setImageUploading] = useState(false);
  async function imageInputHandler(e: any) {
    const data = await imageUpload(e?.target?.files[0], setImageUploading);
    formik.setFieldValue("popup_image", data?.secure_url);
    setUrl(data?.secure_url);
  }

  return (
    <div className="p-6 w-full space-y-4 md:space-y-6 sm:p-8">
      <form
        onSubmit={formik.handleSubmit}
        className="space-y-4 md:space-y-6 bg-white p-5 rounded shadow border"
        action="#"
      >
        <div>
          <InputWithLabel
            label="Admin Panel URL"
            placeholder="Admin Panel URL"
            {...formik.getFieldProps("admin_url")}
            name="admin_url"
          />

          <InputErrorMessage
            touched={formik.touched.admin_url}
            error={formik.errors.admin_url}
          />
        </div>

        <div>
          <InputWithLabel
            label="Website URL"
            placeholder="Website URL"
            {...formik.getFieldProps("client_url")}
            name="client_url"
          />

          <InputErrorMessage
            touched={formik.touched.client_url}
            error={formik.errors.client_url}
          />
        </div>

        <div>
          <InputWithLabel
            label="Add Money Tutorial Youtube Video ID"
            placeholder="Youtube Video ID"
            {...formik.getFieldProps("add_money_tutorial_video_id")}
            name="add_money_tutorial_video_id"
          />

          <img
            className="rounded shadow mt-1"
            src="https://i.ibb.co/g7BQcGX/Capture.png"
            alt=""
          />
          <InputErrorMessage
            touched={formik.touched.add_money_tutorial_video_id}
            error={formik.errors.add_money_tutorial_video_id}
          />
        </div>

        <div>
          <InputWithLabel
            label="Copyright Text"
            placeholder="Copyright Text"
            {...formik.getFieldProps("copyright")}
            name="copyright"
          />

          <InputErrorMessage
            touched={formik.touched.copyright}
            error={formik.errors.copyright}
          />
        </div>

        <div>
          <InputWithLabel
            label="Logo"
            placeholder="Logo"
            {...formik.getFieldProps("logo")}
            name="logo"
          />

          <InputErrorMessage
            touched={formik.touched.logo}
            error={formik.errors.logo}
          />
        </div>
        <div>
          <InputWithLabel
            label="location"
            placeholder="location"
            {...formik.getFieldProps("location")}
            name="location"
          />

          <InputErrorMessage
            touched={formik.touched.location}
            error={formik.errors.location}
          />
        </div>
        <div>
          <div className="flex items-center justify-between mb-2">
            <input
              onChange={imageInputHandler}
              title="upload image"
              type="file"
            />
            <b>{imageUploading ? "Uploading..." : ""}</b>
          </div>

          <img
            className="w-44 rounded pb-3"
            src={url || formik.initialValues.popup_image}
            alt=""
          />
          <InputWithLabel
            label="Popup Image"
            placeholder="Popup Image"
            {...formik.getFieldProps("popup_image")}
            name="popup_image"
          />

          <InputErrorMessage
            touched={formik.touched.popup_image}
            error={formik.errors.popup_image}
          />
        </div>

        <div>
          <InputWithLabel
            label="Popup Text"
            placeholder="Popup Text"
            {...formik.getFieldProps("popup_text")}
            name="popup_text"
          />

          <InputErrorMessage
            touched={formik.touched.popup_text}
            error={formik.errors.popup_text}
          />
        </div>

        <Button type="submit" loading={loading}>
          Update Info
        </Button>
      </form>
    </div>
  );
}

export default function ContactInfoSettings() {
  const { state: rootState }: rootContextType = useRootContext();

  const [should_show_form, set_ssf] = useState<boolean>(false);

  useEffect(() => {
    if (rootState?.web_info) {
      set_ssf(true);
    }
  }, [rootState]);

  return (
    <Layout>
      {should_show_form ? (
        <Form web_info={rootState?.web_info} />
      ) : (
        <h1 className="p-12 text-lg text-pink-600">Loading...</h1>
      )}
    </Layout>
  );
}
