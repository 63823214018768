import { useState, useEffect } from "react";
import SmallButton from "../components/Others/Common/SmallButton";
import WarningPopup from "../components/Others/Common/WarningPopup";
import Layout from "../components/Others/Layout";
import TableLayout from "../components/Others/TableLayout";
import TostMessage from "../components/Others/Utils/TostMessage";
import { rootContextType, useRootContext } from "../context/RootContext";
import makeFetch from "../hooks/makeFetch";
import { orderType } from "../types/rootStateTypes";
import { useOrders } from "../context/OrdersContext";
import axiosInstance from "../helper/axiosInstance";

const Orders = () => {
  const { dispatch: rootDispatch }: rootContextType = useRootContext();
  const {
    orders,
    setOrders,
    page,
    setPage,
    limit,
    setLimit,
    total_pages,
    setTotalPages,
    total_orders,
    setTotalOrders,
  } = useOrders();

  const [search, setSearch] = useState("");

  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showCompletePopup, setShowCompletePopup] = useState(false);
  const [selected, setSelected] = useState<orderType>();
  const [loading, setLoading] = useState(false);

  const [previewOrders, setPreviewOrders] = useState<orderType[]>();

  const [previewVal, setPreviewVal] = useState("all");

  function changeSelect(e: any) {
    const value = e?.target?.value?.toLowerCase();
    setPreviewVal(value);
    if (value === "pending") {
      const filtered: any = orders?.filter((d) => d?.status === "pending");
      setPreviewOrders(filtered);
    } else if (value === "completed") {
      const filtered: any = orders?.filter((d) => d?.status === "completed");
      setPreviewOrders(filtered);
    } else if (value === "canceled") {
      const filtered: any = orders?.filter((d) => d?.status === "canceled");
      setPreviewOrders(filtered);
    } else {
      setPreviewOrders(orders);
    }
  }

  function cancelPopup() {
    setShowCancelPopup(false);
    setShowCompletePopup(false);
  }

  async function updateStatus(status: string) {
    let providedData: any = {
      order_id: selected?._id,
      status,
    };

    if (status === "canceled") {
      providedData.user_id = selected?.user?._id;
      providedData.amount = selected?.price;
    }

    const { result } = await makeFetch(
      "put",
      "order/update-status",
      providedData,
      setLoading
    );

    cancelPopup();

    if (result.error) return TostMessage(result.error, "error");

    setOrders((prev: orderType[]) => [
      ...prev.filter((order) =>
        order._id === selected?._id ? result.data?.order : order
      ),
    ]);

    setOrders((prev: orderType[]) => {
      return prev.map((order) =>
        order._id === selected?._id ? result.data.order : order
      );
    });
  }

  useEffect(() => {
    setOrders(orders);
    if (previewVal === "pending") {
      const filtered: any = orders?.filter((d) => d?.status === "pending");
      setPreviewOrders(filtered);
    } else if (previewVal === "completed") {
      const filtered: any = orders?.filter((d) => d?.status === "completed");
      setPreviewOrders(filtered);
    } else if (previewVal === "canceled") {
      const filtered: any = orders?.filter((d) => d?.status === "canceled");
      setPreviewOrders(filtered);
    } else {
      setPreviewOrders(orders);
    }
  }, [orders, previewVal, setOrders]);

  const [loadingId, setLoadingId] = useState<any>();

  async function deleteOrder(order_id?: string) {
    setLoadingId(order_id);
    const { result } = await makeFetch(
      "delete",
      "order/delete",
      {
        order_id,
      },
      setLoading
    );
    if (result.error) return TostMessage(result.error, "error");
    rootDispatch({
      type: "remove_deleted_order",
      payload: result?.data?.deleted,
    });

    setOrders((prev: orderType[]) => [
      ...prev.filter((order) => order._id !== order_id),
    ]);
  }

  const [paginationLoading, setPaginationLoading] = useState<any>(false);

  async function handleShowPerPage(e: React.ChangeEvent<HTMLSelectElement>) {
    setPaginationLoading(true);
    const limit_value: any = e.target.value;
    setLimit(limit_value);
    try {
      axiosInstance
        .get(
          `/get-orders?page=${page}&limit=${
            limit_value === "all" ? total_orders : limit_value
          }`,
          {
            headers: {
              token: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((result) => {
          setOrders(result?.data?.orders);
          setTotalOrders(result?.data?.totalOrders);
          setTotalPages(result?.data?.totalPages);
          setPaginationLoading(false);
        });
    } catch (error) {}
  }
  async function changePagination(curr_page: any) {
    setPaginationLoading(true);
    try {
      axiosInstance
        .get(`/get-orders?page=${curr_page}&limit=${limit}`, {
          headers: {
            token: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((result) => {
          setOrders(result?.data?.orders);
          setTotalOrders(result?.data?.totalOrders);
          setTotalPages(result?.data?.totalPages);
          setPaginationLoading(false);
        });
    } catch (error) {}
  }

  // fetched orders
  useEffect(() => {
    if (orders && orders?.length > 0) return;
    const token = localStorage.getItem("token");
    axiosInstance
      .get(`/get-orders?page=${page}&limit=${limit}`, {
        headers: {
          token: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setOrders(result?.data?.orders);
        setTotalOrders(result?.data?.totalOrders);
        setTotalPages(result?.data?.totalPages);
      });
  }, [page, limit, orders, setOrders, setTotalPages, setTotalOrders]);

  return (
    <Layout>
      <TableLayout
        paginations={{
          currentPage: page,
          totalPages: total_pages,
          onPageChange: setPage,
          changePagination: changePagination,
          showPerPageChange: handleShowPerPage,
          loading: paginationLoading,
          totalItems: total_orders,
        }}
        setSeaerch={setSearch}
        pageTitle="Orders"
        selectOptions={["All", "Pending", "Completed", "Canceled"]}
        allStatusCount={{
          pending: orders?.filter((o) => o?.status === "pending").length,
          completed: orders?.filter((o) => o?.status === "completed").length,
          canceled: orders?.filter((o) => o?.status === "canceled").length,
        }}
        changeSelect={changeSelect}
        tableHeader={[
          "#",
          "User Email",
          "Title",
          "Delivery Item",
          "Price",
          "Info (user provided) ",
          "Order ID",
          "Status",
          "Actions",
        ]}
      >
        {previewOrders
          ?.filter((o) =>
            o?.order_no
              ?.toLocaleLowerCase()
              .includes(search?.toLocaleLowerCase())
          )
          .map((order, index) => (
            <tr key={order?._id}>
              <td> {index + 1} </td>
              <td>
                <small>{order?.user?.email}</small>
              </td>
              <td> {order?.title} </td>
              <td> {order?.package_name} </td>
              <td>
                <b className="text-pink-900">৳ {order?.price}</b>
              </td>
              <td>
                <div className="flex flex-wrap gap-2">
                  {Object.entries(order?.account_info || {}).map(
                    ([key, val]) => (
                      <div
                        key={key}
                        className="flex items-center space-x-1 rounded"
                      >
                        <p> {key}: </p>
                        <p className="max-w-[180px] break-words bg-yellow-100 rounded whitespace-pre-line">
                          {val}
                        </p>
                      </div>
                    )
                  )}
                </div>
              </td>
              <td>{order?.order_no}</td>
              <td>
                <p className="px-2 py-1 w-fit rounded bg-pink-500 text-white">
                  {order?.status}
                </p>
              </td>
              <td>
                <div className="flex gap-1">
                  {order?.status === "pending" ? (
                    <>
                      <SmallButton
                        onClick={() => {
                          setSelected(order);
                          setShowCompletePopup(true);
                        }}
                        className="bg-green-600"
                      >
                        Complete
                      </SmallButton>
                      <SmallButton
                        onClick={() => {
                          setSelected(order);
                          setShowCancelPopup(true);
                        }}
                        className="bg-red-400"
                      >
                        Cancel
                      </SmallButton>
                    </>
                  ) : (
                    <SmallButton
                      loading={loading && loadingId === order?._id}
                      onClick={() => deleteOrder(order?._id)}
                      className="bg-red-400"
                    >
                      Delete
                    </SmallButton>
                  )}
                </div>
              </td>
            </tr>
          ))}
      </TableLayout>

      {previewOrders?.length === 0 && (
        <div className="text-lg p-5 w-full text-center font-semibold text-yellow-700">
          {previewVal === "all" ? (
            <span> No Orders!</span>
          ) : (
            <p>
              <span>No</span>
              <span className="capitalize mx-2">{previewVal}</span>
              <span>Orders</span>
            </p>
          )}
        </div>
      )}

      <WarningPopup
        cancel={cancelPopup}
        confirmFunc={() => updateStatus("canceled")}
        loading={loading}
        confirmButtonText="Yes, Cancel"
        warningTitle="Want to cancel?"
        warningInfo={`Order ID: ${selected?.order_no}`}
        display={showCancelPopup}
      />

      <WarningPopup
        cancel={cancelPopup}
        confirmFunc={() => updateStatus("completed")}
        loading={loading}
        confirmButtonText="Yes, Confirm"
        warningTitle="Have you delivered properly?"
        warningInfo={`Order ID: ${selected?.order_no}`}
        display={showCompletePopup}
      />
    </Layout>
  );
};

export default Orders;
