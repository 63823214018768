import { useState, useEffect } from "react";
import Input from "../Others/Common/Input";
import { uid } from "uid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import Button from "../Others/Common/Button";
import TostMessage from "../Others/Utils/TostMessage";
import convertToBase64 from "../../helper/convertToBase64";
import { useRootContext } from "../../context/RootContext";
import makeFetch from "../../hooks/makeFetch";

const EditGamePopup = ({ selected, cancelPopup }) => {
  const { dispatch: rootDispatch } = useRootContext();

  const [thumbnail, set_thumbnail] = useState("");
  const [thumbnail_uploading, set_thumbnail_uploading] = useState(false);
  const [recharge, set_recharge] = useState([]);
  const [game_title, set_game_title] = useState("");
  const [soldOutGame, setSoldOutGame] = useState(false);
  const [rule, set_rule] = useState("");
  const [specific_rules, set_specific_rules] = useState([]);
  const [loading, setLoading] = useState(false);

  function remove_rule(i) {
    const filtered = specific_rules?.filter((_, ind) => ind !== i);
    set_specific_rules(filtered);
  }

  function add_rule() {
    if (rule) {
      set_specific_rules((p) => [...p, rule]);
      set_rule("");
    }
  }

  const [field_name, set_field_name] = useState("");
  const [specific_fields, set_specific_fields] = useState([]);
  function remove_field(i) {
    const filtered = specific_fields?.filter((_, ind) => ind !== i);
    set_specific_fields(filtered);
  }

  function add_field() {
    if (field_name) {
      set_specific_fields((p) => [...p, field_name]);
      set_field_name("");
    }
  }

  const [recharge_details, set_recharge_details] = useState({
    title: "",
    price: 0,
    sold_out: false,
  });

  const [selectedUpdateRecharge, setSelectedUpdateRecharge] = useState();
  const [updateRechargeTitle, setUpdateRechargeTitle] = useState("");
  const [updateRechargePrice, setUpdateRechargePrice] = useState(0);
  const [updateRechargeSoldOut, setUpdateRechargeSoldOut] = useState(false);

  useEffect(() => {
    setUpdateRechargeTitle(selectedUpdateRecharge?.title || "");
    setUpdateRechargePrice(selectedUpdateRecharge?.price || 0);
    setUpdateRechargeSoldOut(selectedUpdateRecharge?.sold_out || false);
  }, [selectedUpdateRecharge]);

  function updateRecharge() {
    const obj = {
      ...selectedUpdateRecharge,
      title: updateRechargeTitle,
      price: updateRechargePrice,
      sold_out: updateRechargeSoldOut,
    };

    set_recharge((prev) =>
      prev?.map((recharge) =>
        recharge?.uid === selectedUpdateRecharge?.uid ? obj : recharge
      )
    );

    setSelectedUpdateRecharge({});
  }

  function remove_recharge(uid) {
    set_recharge((p) => {
      return p?.filter((r) => r?.uid !== uid);
    });
  }

  function add_recharge() {
    if (recharge_details?.title && recharge_details?.price) {
      set_recharge((p) => [...p, { ...recharge_details, uid: uid() }]);
      set_recharge_details({ title: "", price: 0 });
    }
  }

  async function upload_thumbnail(e) {
    const file = e.target.files[0];
    if (!file) return;
    set_thumbnail_uploading(true);
    const img = await convertToBase64(file);
    set_thumbnail(img);
    set_thumbnail_uploading(false);
  }

  async function update_game(e) {
    e?.preventDefault();

    if (recharge?.length < 1) {
      TostMessage('Please provide min 1 "Recharge Package"! ', "warning");
      return;
    }
    if (specific_fields.length < 1) {
      TostMessage('Please provide min 1 "Specific Field"! ', "warning");
      return;
    }
    if (!thumbnail) {
      TostMessage("Please upload thumbnail!", "warning");
      return;
    }

    const game = {
      game_id: selected?._id,
      title: game_title,
      sold_out: soldOutGame,
      recharge,
      specific_fields,
      specific_rules,
      thumbnail,
    };

    const { result } = await makeFetch("put", "game/edit", game, setLoading);
    cancelPopup();
    if (result?.error) return TostMessage(result.error, "error");

    rootDispatch({ type: "put_updated_game", payload: result?.data?.game });
    TostMessage("Successfully Updated!");
  }

  function game_recharge_handler(e) {
    if (e.code === "Enter") {
      add_recharge();
    }
  }

  function game_recharge_update_handler(e) {
    if (e.code === "Enter") {
      updateRecharge();
    }
  }

  useEffect(() => {
    set_recharge(selected?.recharge);
    set_thumbnail(selected?.thumbnail);
    set_game_title(selected?.title);
    setSoldOutGame(selected?.sold_out);
    set_specific_fields(selected?.specific_fields || []);
    set_specific_rules(selected?.specific_rules || []);
  }, [selected]);

  return (
    <div className="overflow-hidden fixed inset-0 m-auto z-[111] justify-center items-center  h-modal flex">
      <div
        onClick={cancelPopup}
        className="w-full h-screen bg-black bg-opacity-50 z-40 fixed inset-0 m-auto"
      />

      <div className="relative z-50 px-4 w-full max-w-2xl">
        <div
          style={{ maxHeight: "80vh", overflowY: "auto" }}
          className="relative bg-white rounded-lg shadow-xl shadow-gray-700"
        >
          <div className="flex justify-between items-start p-5 rounded-t border-b">
            <h3 className="text-xl font-semibold">Edit Item</h3>
            <button
              onClick={cancelPopup}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-toggle="product-modal"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>

          <div className="p-6 space-y-6">
            <form onSubmit={update_game}>
              <div className="w-full">
                <label
                  htmlFor="game_title"
                  className="block mb-2 text-base font-medium text-gray-900"
                >
                  Game Title
                </label>
                <Input
                  placeholder="Example: Free Fire"
                  id="game_title"
                  value={game_title}
                  onChange={(e) => set_game_title(e.target.value)}
                  required
                />
              </div>

              <div className="w-full">
                <label
                  htmlFor="soldout"
                  className="block mb-2 mt-4 text-base font-medium text-gray-900"
                >
                  Sold Out - {game_title}
                </label>
                <input
                  id="soldout"
                  type="checkbox"
                  checked={soldOutGame}
                  onChange={(e) => setSoldOutGame(e.target.checked)}
                  value=""
                  className="w-9 h-9 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>

              {thumbnail ? (
                <div className="py-6 flex flex-col">
                  <p className="block mb-2 text-base font-medium text-gray-900">
                    Thumbnail
                  </p>
                  <div className="relative">
                    <span
                      onClick={() => set_thumbnail("")}
                      className="absolute left-1 top-1 bg-red-500 text-red-100 rounded shadow w-6 h-6 flex items-center justify-center cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </span>
                    <img
                      src={thumbnail}
                      className="w-40 mx-auto lg:mx-0 rounded ring-2"
                      alt="game thumbnail"
                    />
                  </div>
                </div>
              ) : (
                <div className="flex justify-center items-center w-full py-6">
                  {thumbnail_uploading ? (
                    <div className="w-full h-40 flex items-center justify-center relative">
                      <div className="w-full h-40 bg-black animate-pulse rounded"></div>
                      <p className="absolute inset-0 m-auto text-base font-medium w-fit h-fit text-white">
                        Uploading...
                      </p>
                    </div>
                  ) : (
                    <label
                      htmlFor="thumbnail"
                      className="flex flex-col w-full h-32 rounded border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-50"
                    >
                      <div className="flex flex-col justify-center items-center pt-5 pb-6">
                        <svg
                          className="w-10 h-10 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                          ></path>
                        </svg>
                        <p className="py-1 text-sm text-gray-600">
                          Upload a thumbnail or drag and drop
                        </p>
                        <p className="text-xs text-gray-500">
                          PNG, JPG, GIF up to 10MB
                        </p>
                      </div>
                      <input
                        required
                        type="file"
                        className=""
                        id="thumbnail"
                        onChange={upload_thumbnail}
                      />
                    </label>
                  )}
                </div>
              )}

              <div>
                <p className="block mb-2 text-base font-medium text-gray-900">
                  All Recharge
                </p>
                <div className="flex flex-wrap gap-2">
                  {recharge?.map((r, i) => (
                    <div
                      key={r?.uid}
                      className={`bg-gray-200 text-dark-primary p-5 rounded shadow text-xs font-medium space-x-2 relative ${
                        r?.uid === selectedUpdateRecharge?.uid &&
                        "animate-pulse"
                      }`}
                    >
                      <span>{r?.title}</span>
                      <b>৳ {r?.price}</b>

                      <div className="absolute top-1 right-1 text-xs flex items-center gap-x-2">
                        {r?.sold_out && (
                          <small className="bg-yellow-200 px-1">sold out</small>
                        )}

                        <button
                          type="button"
                          title="Edit Recharge"
                          onClick={() => setSelectedUpdateRecharge(r)}
                          className="text-red-500"
                        >
                          <FontAwesomeIcon icon={faPencil} />
                        </button>
                        <button
                          type="button"
                          title="Delete Recharge"
                          onClick={() => remove_recharge(r?.uid)}
                          className="text-red-500"
                        >
                          <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Update Recharge */}
                {selectedUpdateRecharge?.title && (
                  <div className="flex flex-items items-end justify-between gap-3 flex-col sm:flex-row py-6">
                    <div className="w-full">
                      <label
                        htmlFor="update_pac_title"
                        className="text-sm font-medium block mb-1"
                      >
                        Package Title
                      </label>
                      <Input
                        required={false}
                        id="update_pac_title"
                        value={updateRechargeTitle}
                        onKeyUp={game_recharge_update_handler}
                        placeholder="Example:  500 DIAMOND"
                        onChange={(e) => setUpdateRechargeTitle(e.target.value)}
                      />
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="update_pac_price"
                        className="text-sm font-medium block mb-1"
                      >
                        Package Price
                      </label>
                      <Input
                        required={false}
                        value={updateRechargePrice}
                        id="update_pac_price"
                        type="number"
                        onKeyUp={game_recharge_update_handler}
                        placeholder="Example:  200"
                        onChange={(e) => setUpdateRechargePrice(e.target.value)}
                      />
                    </div>
                    <div className="w-fit">
                      <label
                        htmlFor="soldout"
                        className="text-xs font-medium text-gray-900 dark:text-gray-300"
                      >
                        SoldOut
                      </label>
                      <input
                        id="soldout"
                        checked={updateRechargeSoldOut}
                        type="checkbox"
                        onChange={(e) =>
                          setUpdateRechargeSoldOut(e.target.checked)
                        }
                        value=""
                        className="w-9 h-9 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </div>
                    <button
                      onClick={updateRecharge}
                      type="button"
                      className="h-11 px-4 rounded focus:ring-2 shadow bg-cyan-900 text-white"
                    >
                      Update
                    </button>
                  </div>
                )}

                {/* Add Recharge */}
                <div className="flex flex-items items-end justify-between gap-3 flex-col sm:flex-row py-6">
                  <div className="w-full">
                    <label
                      htmlFor="pac_title"
                      className="text-sm font-medium block mb-1"
                    >
                      Package Title
                    </label>
                    <Input
                      required={false}
                      id="pac_title"
                      value={recharge_details?.title}
                      onKeyUp={game_recharge_handler}
                      placeholder="Example:  500 DIAMOND"
                      onChange={(e) =>
                        set_recharge_details((p) => ({
                          ...p,
                          title: e.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="w-full">
                    <label
                      htmlFor="pac_price"
                      className="text-sm font-medium block mb-1"
                    >
                      Package Price
                    </label>
                    <Input
                      required={false}
                      value={
                        recharge_details?.price > 0 && recharge_details?.price
                      }
                      id="pac_price"
                      type="number"
                      onKeyUp={game_recharge_handler}
                      placeholder="Example:  200"
                      onChange={(e) =>
                        set_recharge_details((p) => ({
                          ...p,
                          price: e?.target.value > 0 ? e.target.value : 0,
                        }))
                      }
                    />
                  </div>
                  <button
                    onClick={add_recharge}
                    type="button"
                    className="h-11 px-4 rounded focus:ring-2 shadow bg-cyan-900 text-white"
                  >
                    Add
                  </button>
                </div>
              </div>

              <div className="py-6">
                <p className="mb-1">
                  Update Specific Field <small>(optional)</small>
                </p>
                <div className="flex flex-wrap gap-2 py-3">
                  {specific_fields?.map((f, i) => (
                    <div
                      key={f + i}
                      className="bg-gray-200 border rounded px-5 py-3 relative"
                    >
                      <span> {f} </span>
                      <span
                        onClick={() => remove_field(i)}
                        className="cursor-pointer text-xs absolute top-1 right-1"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex items-center justify-between gap-x-2">
                  <Input
                    required={false}
                    value={field_name || ""}
                    onChange={(e) => set_field_name(e?.target?.value)}
                    placeholder="Field name, Exmaple: Player Id"
                  />
                  <button
                    type="button"
                    onClick={add_field}
                    className="px-3 gap-x-1 h-11 flex items-center justify-center bg-cyan-800 text-white rounded"
                  >
                    <span>Add </span>
                    <span>Field</span>
                  </button>
                </div>
              </div>

              <div className="py-6">
                <p className="mb-1">
                  Update Specific Rules <small>(optional)</small>
                </p>
                <div className="flex flex-wrap gap-2 py-3">
                  {specific_rules?.map((f, i) => (
                    <div
                      key={f + i}
                      className="bg-gray-200 border rounded px-5 py-3 relative"
                    >
                      <span> {f} </span>
                      <span
                        onClick={() => remove_rule(i)}
                        className="cursor-pointer text-xs absolute top-1 right-1"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex items-center justify-between gap-x-2">
                  <Input
                    required={false}
                    value={rule || ""}
                    onChange={(e) => set_rule(e?.target?.value)}
                    placeholder="Enter Rule, Conditions"
                  />
                  <button
                    type="button"
                    onClick={add_rule}
                    className="px-3 gap-x-1 h-11 flex items-center justify-center bg-cyan-800 text-white rounded"
                  >
                    <span>Add </span>
                    <span>Rule</span>
                  </button>
                </div>
              </div>

              <div className="py-6 rounded-b border-t border-gray-200">
                <Button type="submit" loading={loading}>
                  Update
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditGamePopup;
