import { Routes, Route } from "react-router-dom";

// protect route middleware
import { PublicRoute, RequireAuthorize } from "./middleware/protect_route";

// Pages
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Users from "./pages/Users";
import Games from "./pages/Games";
import Profile from "./pages/Profile";
import Security from "./pages/Security";
import WebInfoSettings from "./pages/WebInfoSettings";
import BannersSetting from "./pages/BannersSetting";
import PaymentMethod from "./pages/PaymentMethod";
import ContactInfoSettings from "./pages/ContactInfoSettings";
import Orders from "./pages/Orders";
import Deposits from "./pages/Deposits";

const all_routes: any = {
  "/": (
    <RequireAuthorize>
      <Home />
    </RequireAuthorize>
  ),

  "/games": (
    <RequireAuthorize>
      <Games />
    </RequireAuthorize>
  ),
  "/deposits": (
    <RequireAuthorize>
      <Deposits />
    </RequireAuthorize>
  ),
  "/orders": (
    <RequireAuthorize>
      <Orders />
    </RequireAuthorize>
  ),
  "/login": (
    <PublicRoute>
      <Login />
    </PublicRoute>
  ),

  "/users": (
    <RequireAuthorize>
      <Users />
    </RequireAuthorize>
  ),

  "/profile": (
    <RequireAuthorize>
      <Profile />
    </RequireAuthorize>
  ),
  "/security": (
    <RequireAuthorize>
      <Security />
    </RequireAuthorize>
  ),
  "/website-settings": (
    <RequireAuthorize>
      <WebInfoSettings />
    </RequireAuthorize>
  ),
  "/contact-settings": (
    <RequireAuthorize>
      <ContactInfoSettings />
    </RequireAuthorize>
  ),
  "/banners": (
    <RequireAuthorize>
      <BannersSetting />
    </RequireAuthorize>
  ),
  "/payment-methods": (
    <RequireAuthorize>
      <PaymentMethod />
    </RequireAuthorize>
  ),

  "*": <NotFound />,
};

export default function App() {
  return (
    <main className="min-h-screen bg-gray-50 flex flex-col">
      <Routes>
        {Object.entries(all_routes || {}).map(([key, val]: any) =>
          val?.element ? (
            <Route key={key} path={key} element={val?.element}>
              {Object.entries(val?.sub_pages || {}).map(([key, val]: any) => (
                <Route
                  key={key}
                  path={key === "root" ? "" : key}
                  element={val}
                />
              ))}
            </Route>
          ) : (
            <Route key={key} path={key} element={val} />
          )
        )}
      </Routes>
    </main>
  );
}
