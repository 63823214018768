type values_type = {
  password?: string;
  confirm_password?: string;
};

export default function change_password_validation(values: values_type) {
  const errors: values_type = {};

  if (!values.password) {
    errors.password = "Password is Required!";
  } else if (values.password.length < 6 || values.password.length > 20) {
    errors.password = "Must be less then 6 and greater than 20 characters!";
  }

  if (!values.confirm_password) {
    errors.confirm_password = "Confirm Password is Required!";
  } else if (values.password !== values.confirm_password) {
    errors.confirm_password = "Password Doesn't match!";
  }

  return errors;
}
