import { useState, useEffect } from "react";

import Button from "../components/Others/Common/Button";
import InputWithLabel from "../components/Others/Common/InputWithLabel";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { useFormik } from "formik";
import InputErrorMessage from "../components/Others/Utils/InputErrorMessage";

import login_validation from "../helper/formikValidation/login_validation";

import axiosInstance from "../helper/axiosInstance";
import { useAuthContext, authContextType } from "../context/AuthContext";
import { useRootContext, rootContextType } from "../context/RootContext";

const Signup = () => {
  const { dispatch: authDispatch }: authContextType = useAuthContext();
  const { dispatch: rootDispatch }: rootContextType = useRootContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: signin,
    validate: login_validation,
  });

  function signin(values: any) {
    setLoading(true);
    axiosInstance
      .post(`/auth/admin-login`, values)
      .then((res) => {
        const token = res?.data?.token;
        localStorage.setItem("token", token);
        authDispatch({ type: "set_user", payload: res?.data?.user });

        axiosInstance
          .get("/admin-data", {
            headers: {
              token: `Bearer ${token}`,
            },
          })
          .then((result) => {
            rootDispatch({
              type: "set_data",
              payload: {
                games: result?.data?.games,
                orders: result?.data?.orders,
                deposits: result?.data?.deposits,
                users: result?.data?.users,
                ...result.data?.admin_details,
              },
            });
          })
          .catch((error) => {
            setError(error?.response?.data?.message || error?.message);
          });
      })
      .catch((error) => {
        setError(error?.response?.data?.message || error?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  }, [error]);

  return (
    <section className="py-12">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto">
        <div className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
          <span className="mr-2">Login</span>
        </div>
        <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            {error && (
              <p className="text-red-500 flex items-center gap-x-2">
                <FontAwesomeIcon icon={faExclamationCircle} />
                <span>{error}</span>
              </p>
            )}
            <form
              onSubmit={formik.handleSubmit}
              className="space-y-4 md:space-y-6"
              action="#"
            >
              <div>
                <InputWithLabel
                  label="Email Address"
                  placeholder="Email Address"
                  type="email"
                  {...formik.getFieldProps("email")}
                  name="email"
                />

                <InputErrorMessage
                  touched={formik.touched.email}
                  error={formik.errors.email}
                />
              </div>

              <div>
                <InputWithLabel
                  label="New Password"
                  type="password"
                  {...formik.getFieldProps("password")}
                  name="password"
                />

                <InputErrorMessage
                  touched={formik.touched.password}
                  error={formik.errors.password}
                />
              </div>

              <Button type="submit" loading={loading}>
                Login
              </Button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Signup;
