import Pagination from "../../Utils/Paginations";
import TableLayoutHeader from "./TableLayoutHeader";

interface propsType {
  tableHeader: Array<string>;
  pageTitle?: string;
  setSeaerch?: any;
  children: React.ReactNode;
  allStatusCount?: object;
  headerButton?: {
    text: string;
    func: any;
  };
  selectOptions?: Array<string>;
  changeSelect?: any;
  paginations?: {
    currentPage?: any;
    totalPages?: any;
    onPageChange?: any;
    changePagination?: any;
    showPerPageChange?: any;
    loading?: any;
    totalItems?: any;
  };
}
const TableLayout = ({
  tableHeader,
  setSeaerch,
  pageTitle,
  children,
  headerButton,
  selectOptions,
  changeSelect,
  allStatusCount,
  paginations,
}: propsType) => {
  return (
    <div>
      <TableLayoutHeader
        headerButton={headerButton}
        pageTitle={pageTitle}
        setSearch={setSeaerch}
      />

      {selectOptions && selectOptions?.length > 0 && (
        <div className="mx-4 mb-2 flex flex-col-reverse gap-3 sm:flex-row items-center justify-between">
          <select
            onChange={changeSelect}
            className="py-2 pl-2 ring-1 ring-gray-400 border-r-[8px] border-white bg-white shadow flex items-center rounded focus:ring w-full sm:w-40"
            title="select"
            id="select_type"
          >
            {selectOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <div className="flex items-center flex-wrap gap-5 justify-center sm:justify-end">
            {Object.entries(allStatusCount || {}).map(([key, val]) => (
              <p key={key} className="space-x-0.5">
                <small
                  className={`${key === "success" && "text-blue-600"} ${
                    key === "completed" && "text-blue-600"
                  } ${key === "rejected" && "text-red-500"} ${
                    key === "canceled" && "text-red-500"
                  } ${key === "pending" && "text-yellow-700"} capitalize`}
                >
                  {key}
                </small>
                <small>({val})</small>
              </p>
            ))}
          </div>
        </div>
      )}

      {paginations?.showPerPageChange ? (
        <div className="my-2 mx-4 flex justify-between items-center">
          <div className="flex flex-col md:flex-row items-center gap-1 text-sm">
            <label htmlFor="showpp">Show Per Page</label>
            <select
              onChange={paginations?.showPerPageChange}
              id="showpp"
              className="px-2 text-sm py-1.5 border-r-4 border-white bg-white ring-1 ring-gray-300 focus:ring-blue-600 focus:ring-2 rounded"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
              <option value="all">All</option>
            </select>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-1">
            <small>
              Total: <b>{paginations?.totalItems}</b>
            </small>
          </div>
        </div>
      ) : null}

      <div className="flex flex-col mx-4 border relative">
        <div className="max-h-[75vh] overflow-auto rounded-sm">
          <div className="inline-block min-w-full align-middle">
            {paginations?.loading ? (
              <div className="w-full h-full absolute inset-0 m-auto bg-white bg-opacity-80 flex items-center justify-center text-black">
                <span>Loading...</span>
              </div>
            ) : null}

            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200 table-fixed">
                <thead className="bg-gray-200">
                  <tr className="hover:bg-gray-200">
                    {tableHeader?.map((text) => (
                      <th
                        key={text}
                        scope="col"
                        className="px-2 py-3 text-xs font-medium tracking-wide text-left text-gray-900 whitespace-nowrap capitalize lg:px-3"
                      >
                        {text}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {children}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {paginations?.totalPages ? (
        <div className="mx-4 py-2">
          <Pagination
            currentPage={paginations?.currentPage}
            totalPages={paginations?.totalPages}
            onPageChange={paginations?.onPageChange}
            changePagination={paginations?.changePagination}
          />
        </div>
      ) : null}
    </div>
  );
};

export default TableLayout;
